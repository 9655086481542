import React, { useEffect, useState } from "react";
import { Divider, Row, Col, Radio } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";

const StepItemWidget = (props) => {
  const { itemInfo, onCheck, onRemove, playerIndex, playerInfo } = props;

  return (
    <div style={{ marginBottom: 20 }}>
      <div className="block" style={{ fontWeight: 600 }}>
        <Row justify={"space-between"}>
          <Col>
            <Radio
              onChange={(e) => {
                onCheck(playerIndex, itemInfo.id, itemInfo.price, itemInfo.title);
              }}
              checked={
                playerInfo.sel_trip != "" && playerInfo.sel_trip == itemInfo.id
              }
            >
              <span className="title">{itemInfo.title}</span>
            </Radio>
          </Col>
          <Col>
            {itemInfo.description} | ${itemInfo.price}
          </Col>
        </Row>

        {/* <Divider
          style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
        ></Divider>
        <div style={{ marginTop: 20, marginBottom: 20 }}></div>
        <Divider /> */}
        {/* <div style={{marginTop: 20}}>
                    <CommonButton
                        onClick={() => {
                            onRemove(itemInfo.id)
                        }}
                        style={{
                            color: "var(--whiteColor)",
                            backgroundColor: "#8f1c15",
                            padding: "6px 20px",
                        }}
                    >
                        Remove Selection
                    </CommonButton>
                </div> */}
      </div>
    </div>
  );
};
export default StepItemWidget;
