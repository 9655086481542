import React from "react";
import { useState } from "react";
import { Row, Col, Input, message } from "antd";
import Icon from "@ant-design/icons";
import "./MainSlide.css";

import oneImg from "../../../assets/images/home/1.png";
import twoImg from "../../../assets/images/home/2.png";
import threeImg from "../../../assets/images/home/3.png";
import fourImg from "../../../assets/images/home/4.png";
import LogoImg from "../../../assets/images/mark.png";
import AlertImg from "../../../assets/images/ic_alert.png";
import { ReactComponent as RightArrowIcon } from "../../../assets/icons/right-arrow-icon.svg";

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { ReactComponent as userIcon } from "../../../assets/icons/ic_user.svg";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import NormalButton from "components/ButtonWidgets/NormalButton/NormalButton";
import InputPassword from "components/InputWidgets/InputPassword/InputPassword";
import ButtonWithIcon from "components/ButtonWidgets/ButtonWithIcon/ButtonWithIcon";
import { showMyAlertDialog } from "components/MyAlertDialog/showMyAlertDialog";
import { ValidateEmail } from "utils/GlobalFunctions";
import { apiLogin } from "services/userServices";
import { ROUTE_DASHBOARD } from "navigation/CONSTANTS";
import { useDispatch } from "react-redux";
import { updateUser } from "redux/actions/userActions";
import { showForgotPassModal } from "../ForgotPassModal/showForgotPassModal";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [oneImg, twoImg, threeImg, fourImg];

const MainSlide = (props) => {
  const { resStatus } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [userDetail, setUserDetail] = useState({ email: "", password: "" });
  const [wrongList, setWrongList] = useState([]);
  const [email_err_msg, setEmailErrMsg] = useState("");
  const [password_err_msg, setPasswordErrMsg] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const validateFields = () => {
    const { email, password } = userDetail;
    let tmpWrongList = [];
    if (email === undefined || email === "") {
      tmpWrongList.push("email");
      setEmailErrMsg("This field is required");
    } else {
      if (ValidateEmail(email) === false) {
        tmpWrongList.push("email");
        setEmailErrMsg("Not a valid email address");
      }
    }
    if (password === undefined || password === "") {
      tmpWrongList.push("password");
      setPasswordErrMsg("This field is required");
    }
    setWrongList(tmpWrongList);
    return tmpWrongList.length === 0;
  };
  const onClickNext = () => {
    if (validateFields()) {
      setIsBusy(true);
      apiLogin(userDetail)
        .then((res) => {
          setIsBusy(false);
          if (res.result) {
            dispatch(updateUser({ ...res.user, token: res.msg }));
            history.push(ROUTE_DASHBOARD);
          } else {
            message.error(res.msg);
          }
        })
        .catch((err) => {
          message.error("Something went wrong");
        });
    }
  };
  const showAlertDlg = async () => {
    let dlgRes = await showMyAlertDialog({});
    if (dlgRes == null) return;
  };
  const onClickForgot = async () => {
    let dlgRes = await showForgotPassModal();
    if (dlgRes == null) return;
    if (dlgRes) {

    }
  }
  return (
    <div className="main-slide">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <div className="alert-rect">
            <img src={AlertImg} className="alert-img" />
            <div className="alert-text">
              <p>Registration is open</p>
              <p>for the 2024-25 season</p>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <div className="slide-left">
            <div className="login-section">
              <Row
                style={{ marginTop: 50 }}
                gutter={[32, 16]}
                align={"middle"}
                justify={"center"}
              >
                <Col>
                  <Link to="/">
                    <img
                      src={LogoImg}
                      style={{
                        width: "150px",
                        height: "auto",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                </Col>
                <Col style={{ textAlign: "center" }}>
                  <p className="slide-title" style={{ marginBottom: 0 }}>
                    MW REC BASKETBALL
                  </p>
                </Col>
              </Row>
              <Row
                style={{ marginTop: 50, paddingBottom: 50 }}
                gutter={[32, 16]}
                align={"middle"}
                justify={"center"}
              >
                <Col xs={24} sm={24} md={24} xl={24} xxl={10}>
                  <div className="email-input">
                    <Input
                      type="text"
                      prefix={
                        <Icon component={userIcon} className="menu-item-icon" />
                      }
                      suffix={""}
                      placeholder="Email"
                      value={
                        userDetail.email === undefined ? "" : userDetail.email
                      }
                      onChange={(e) => {
                        if (wrongList.includes("email")) {
                          let tmpList = [...wrongList];
                          setWrongList(tmpList.filter((x) => x !== "email"));
                        }
                        setUserDetail({ ...userDetail, email: e.target.value });
                      }}
                      style={{
                        border:
                          wrongList.includes("email") === true
                            ? "1px solid var(--redColor)"
                            : "1px solid var(--greyColor)",
                      }}
                    />
                  </div>
                  {wrongList.includes("email") && (
                    <div
                      style={{
                        color: "rgb(244, 67, 54)",
                        fontSize: 12,
                        paddingTop: 1,
                        textAlign: "left",
                        paddingLeft: 50,
                      }}
                    >
                      {email_err_msg}
                    </div>
                  )}
                  <div style={{ marginTop: 15 }}>
                    <InputPassword
                      userDetail={userDetail}
                      setUserDetail={setUserDetail}
                      wrongList={wrongList}
                      setWrongList={setWrongList}
                      placeholder={"Password"}
                      onClick={onClickNext}
                      type={"login"}
                    />
                  </div>
                  {wrongList.includes("password") && (
                    <div
                      style={{
                        color: "rgb(244, 67, 54)",
                        fontSize: 12,
                        paddingTop: 1,
                        textAlign: "left",
                        paddingLeft: 50,
                      }}
                    >
                      {password_err_msg}
                    </div>
                  )}
                  <div style={{ marginTop: 15 }}>
                    <NormalButton
                      style={{
                        backgroundColor: "var(--markColor)",
                      }}
                      onClick={onClickNext}
                      isBusy={isBusy}
                    >
                      Login
                    </NormalButton>
                  </div>
                  <div>
                    <p
                      className="forgot-title"
                      onClick={onClickForgot}
                      style={{ marginBottom: 0, cursor: "hover" }}
                    >
                      Forgot Password
                    </p>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} xl={24} xxl={4}>
                  <div
                    className="slide-title"
                    style={{ marginBottom: 0, textAlign: "center" }}
                  >
                    OR
                  </div>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  xl={24}
                  xxl={10}
                  style={{
                    textAlign: "center",
                    margin: "auto",
                    display: "contents",
                  }}
                >
                  <div className="join-btn-rect">
                    {resStatus ? (
                      <Link to="/register">
                        <ButtonWithIcon
                          icon={
                            <ArrowRightOutlined
                              style={{ color: "var(--markColor)" }}
                            />
                          }
                          text="Register"
                          customClass={`join-btn`}
                        />
                      </Link>
                    ) : (
                      <ButtonWithIcon
                        icon={
                          <ArrowRightOutlined
                            style={{ color: "var(--markColor)" }}
                          />
                        }
                        onClick={() => {
                          showAlertDlg();
                        }}
                        text="Register"
                        customClass={`join-btn`}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      <p className="slide-title padding-title">
        Be organized, Be successful...BeClutch!
      </p>
      <p className="slide-description padding-description">
        Meet the challenges of group organization and stay connected with
        BeClutch. Whether you are a leader or member you now have the ability to
        communicate and organize all group needs from one App. Handle
        communication via chats, coordinate schedules, calendar events, send
        alerts, receive reminders and manage finances with intuitive and easy to
        navigate interfaces.
      </p>
    </div>
  );
};

export default MainSlide;
