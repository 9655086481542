import React from 'react';
import { Col, Modal, Row, Spin } from 'antd';
import "./ConfirmDlgWidget.css"
import { confirmable } from 'react-confirm';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
const ConfirmDlgWidget = (props) => {
    const { show, proceed, title, okText, cancelText, content, content2, className } = props;
    return (
        <div>
            <Modal
                visible={show}
                destroyOnClose={true}
                onCancel={() => {
                    proceed(false);
                }}
                closable={false}
                maskClosable={false}
                className={className == undefined ? "confirm-modal" : "confirm-modal confirm-large-modal"}
                title={title}
                okText={okText === undefined ? "Yes" : okText}
                cancelText={cancelText === undefined ? "Cancel":cancelText}
                onOk={() => {
                    proceed(true);
                }}
                footer={null}>
                <div>
                    <p className='confirm-text'>{content}</p>
                    {content2 != undefined && <p className='confirm-text'>{content2}</p>}
                </div>
                <Row align={'middle'} justify='center' gutter={16} style={{marginTop: 20}}>
                    <Col>
                        <CommonButton style={{padding: '8px 40px'}} onClick={()=>{
                            proceed(true)
                        }}>Yes</CommonButton>
                    </Col>
                    <Col>
                        <CommonButton style={{padding: '8px 28px', color: 'var(--blackColor)', backgroundColor: 'var(--whiteColor)', border: '1px solid #d9d9d9'}} onClick={()=>{
                            proceed(false)
                        }}>Cancel</CommonButton>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
};

export default confirmable(ConfirmDlgWidget);