import React, { useEffect, useState } from "react";
import StepOne from "./StepOne/StepOne";
import StepTwo from "./StepTwo/StepTwo";
import StepThree from "./StepThree/StepThree";

const StepInfo = (props) => {
  const { userData, setUserData, errorField, setErrorField } = props;

  return (
    <div>
      <StepOne
        userData={userData}
        setUserData={setUserData}
        errorField={errorField}
        setErrorField={setErrorField}
      />
      <StepTwo
        userData={userData}
        setUserData={setUserData}
        errorField={errorField}
        setErrorField={setErrorField}
      />
      {/* <StepThree userData={userData} setUserData={setUserData} errorField={errorField} setErrorField={setErrorField}/> */}
    </div>
  );
};

export default StepInfo;
