import React, { useEffect, useState } from "react";
import { Divider } from "antd";
import StepItemWidget from "./StepItemWidget";
import { getInitData } from "utils/GlobalFunctions";

const StepFee = (props) => {
  const { userData, setUserData, errorField, setErrorField, srcGroupList } =
    props;
  const [itemList, setItemList] = useState([]);
  useEffect(() => {
    const saved_user_info = localStorage.getItem("basketball_player_info");
    if (saved_user_info != null) {
      let savedUserInfo = getInitData();
      setUserData({
        ...userData,
        ...savedUserInfo,
      });
    }
  }, []);
  useEffect(() => {
    let tmpItemList = [];
    if (userData.feeList.length > 0) {
      tmpItemList = [...userData.feeList];
    } else {
      tmpItemList = [...srcGroupList];
    }
    setItemList(tmpItemList);
  }, [userData]);

  const onCheck = (key, id, fee, title) => {
    let tmpList = [...userData.playerInfo];
    for (let i = 0; i < tmpList.length; i++) {
      let itemInfo = tmpList[i];
      if (i == key) {
        itemInfo.sel_trip = id;
        itemInfo.fee = fee;
        itemInfo.fee_label = title;
      }
      tmpList[i] = itemInfo;
    }
    setUserData({
      ...userData,
      playerInfo: [...tmpList],
    });
  };
  const onRemove = (id) => {
    let tmpList = [...itemList];
    let itemInfo = tmpList[id];
    itemInfo.checked = false;
    tmpList[id] = itemInfo;
    setItemList(tmpList);
    setUserData({ ...userData, feeList: [...tmpList] });
  };

  return (
    <div className="profile-info-widget" style={{ border: "none" }}>
      <div>
        {userData.playerInfo.map((player, i) => {
          return (
            <div key={i}>
              <span className="title">
                {player.first_name + ` ` + player.last_name}'s Participant Fees
              </span>
              <Divider></Divider>
              {itemList.map((x, index) => {
                return (
                  <StepItemWidget
                    itemInfo={x}
                    onCheck={onCheck}
                    onRemove={onRemove}
                    playerIndex={i}
                    playerInfo={player}
                    key={index}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StepFee;
