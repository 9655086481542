import React from "react"
import { useState } from "react";
import { Row, Col, Input } from "antd";
import "./ContactSection.css"

import HeroImg from '../../../assets/images/home/1.png';
import GoogleImg from '../../../assets/images/home/app-download-android.png';
import AppleImg from '../../../assets/images/home/app-download-ios.png';
import ButtonWithIcon from "components/ButtonWidgets/ButtonWithIcon/ButtonWithIcon";
import { SendOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const ContactSection = (props) => {
    const { contactData, setContactData, errorField, setErrorField } = props
    return (
        <div className="contact-section">
            <p className="contactus-title">Contact Us</p>
            <Row gutter={32}>
                <Col md={12}>
                    <p className="contactus-description">BeClutch launched in 2020 with the mission to help all types of organizations stay connected. At the workplace, at home, on the go, or wherever else you need to stay in touch and stay organized.</p>

                </Col>
                <Col md={12}>
                    <p className="contactus-description">We encourage you to contact us at support@beclutch.club for launch dates, company announcements or any other general inquiries.</p>

                </Col>
            </Row>
            <Row gutter={[32, 16]} style={{ marginTop: 30 }}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <div className="contact-form-title">Name</div>
                    <Input
                        value={contactData.name}
                        className={errorField !== undefined && errorField.includes("name") ? `contact-form-input common-input-error` : `contact-form-input common-input`}
                        onChange={(e) => {
                            if (errorField.includes("name")) {
                                let errors = errorField.filter((x) => x != "name");
                                setErrorField([...errors]);
                            }
                            setContactData({ ...contactData, name: e.target.value });
                        }} />
                    <div style={{ marginTop: 10 }} className="contact-form-title">Email*</div>
                    <Input
                        value={contactData.email}
                        className={errorField !== undefined && errorField.includes("email") ? `contact-form-input common-input-error` : `contact-form-input common-input`}
                        onChange={(e) => {
                            if (errorField.includes("email")) {
                                let errors = errorField.filter((x) => x != "email");
                                setErrorField([...errors]);
                            }
                            setContactData({ ...contactData, email: e.target.value });
                        }} />
                    <div style={{ marginTop: 10 }} className="contact-form-title">Subject</div>
                    <Input
                        value={contactData.subject}
                        className={errorField !== undefined && errorField.includes("subject") ? `contact-form-input common-input-error` : `contact-form-input common-input`}
                        onChange={(e) => {
                            if (errorField.includes("subject")) {
                                let errors = errorField.filter((x) => x != "subject");
                                setErrorField([...errors]);
                            }
                            setContactData({ ...contactData, subject: e.target.value });
                        }} />
                </Col>
                <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                    <div  className="contact-form-title">Message*</div>
                    <TextArea
                        rows={10}
                        value={contactData.message}
                        className={errorField !== undefined && errorField.includes("message") ? `contact-form-textarea common-input-error` : `contact-form-textarea common-input`}
                        onChange={(e) => {
                            if (errorField.includes("message")) {
                                let errors = errorField.filter((x) => x != "message");
                                setErrorField([...errors]);
                            }
                            setContactData({ ...contactData, message: e.target.value });
                        }} />

                </Col>
            </Row>
            <Row style={{marginTop: 30}}>
                <Col flex={'auto'}></Col>
                <Col>
                    <ButtonWithIcon
                        icon={<SendOutlined style={{ color: 'var(--whiteColor)' }} />}
                        text="Send message"
                        customClass={`contactus-btn`}
                        onClick={() => {
                            // props.history.push(ROUTE_NOTIFICATION);
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default ContactSection