import React from "react";
import { Input, Row, Col, Select } from "antd";

import "./SearchWidget.css";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
const { Search } = Input;
const { Option } = Select;
function SearchWidget(props) {
  const { dataList, onRegister, onRegisterNew, onSearch, selRows, searchKey,  setSearchKey} = props;

  return (
    <Row className="follower-cnt-txt" align="middle" justify="space-between">
      <Col>
        <span>Count: {dataList.length}</span>
      </Col>
      <Col>
        <Row gutter={24} align="middle">
          <Col>
            <Search
              placeholder={"Search participant name"}
              allowClear
              onSearch={(v) => {
                onSearch(v);
              }}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
              value={searchKey}
              enterButton
            />
          </Col>
          {selRows.length > 0 && (
            <Col>
              <CommonButton
                onClick={() => {
                  onRegister();
                }}
              >
                Register {selRows.length}{" "}
                {selRows.length == 1 ? "Participant" : "Participants"}
              </CommonButton>
            </Col>
          )}
          <Col>
            <CommonButton
              onClick={() => {
                onRegisterNew();
              }}
            >
              New Registration
            </CommonButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default SearchWidget;
