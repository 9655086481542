import React from 'react';
import { Link } from 'react-router-dom';

// images
import Logo from '../../../assets/images/logo.png';
import LogoVertical from '../../../assets/icons/logo-vertical.svg';

const MainFooter = () => {
  const currentDate = new Date();

  return (
    <footer className='main-page-footer'>
        <p>Copyright © 2021 BeClutch.club</p>
        <p>All Rights Reserved</p>
    </footer>
  );
};

export default MainFooter;
