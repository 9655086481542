import Icon, { DollarCircleOutlined, SettingOutlined, ShoppingCartOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons';
import { ReactComponent as dashboardIcon } from '../assets/icons/ic_dashboard.svg';
import { ReactComponent as storeIcon } from '../assets/icons/ic_store.svg';
import { ReactComponent as analyticsIcon } from '../assets/icons/ic_analytics.svg';
import { ReactComponent as promotionIcon } from '../assets/icons/ic_promotion.svg';
import { ReactComponent as bestdealIcon } from '../assets/icons/ic_bestdeal.svg';
import { ReactComponent as productIcon } from '../assets/icons/ic_product.svg';
import { ReactComponent as postIcon } from '../assets/icons/ic_post.svg';
import { ReactComponent as followerIcon } from '../assets/icons/ic_follower.svg';
import { ReactComponent as subscriptionIcon } from '../assets/icons/ic_subscription.svg';
import { ReactComponent as agentIcon } from '../assets/icons/ic_agent.svg';


export const ROOT = "/";
export const ROUTE_WELCOME = "/welcome";
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_STORE = "/store";
export const ROUTE_ANALYTICS = "/analytics";
export const ROUTE_PROMOTION = "/promotion";
export const ROUTE_BESTDEAL = "/bestdeal";
export const ROUTE_PRODUCT = "/product";
export const ROUTE_PROMOTED_PRODUCT = "/promoted-product";
export const ROUTE_PROMOTED_PRODUCT_DETAIL = "/promoted-product/detail";
export const ROUTE_PROMOTED_PRODUCT_REVIEW = "/promoted-product/review";
export const ROUTE_POST = "/post";
export const ROUTE_FOLLOWER = "/follower";
export const ROUTE_SUPPLIERS = "/suppliers";
export const ROUTE_SUPPLIER_DETAIL = "/suppliers/detail";
export const ROUTE_SUBSCRIPTION = "/subscription";
export const ROUTE_AGENT = "/agent";
export const ROUTE_USER_PROFILE = "/user-profile";
export const ROUTE_VENDOR_PROFILE = "/vendor-profile";
export const ROUTE_PAYMENT = "/payment";
export const ROUTE_NOTIFICATION = "/notification";

export const PAGE1 = "/page1";
export const AUTH_PAGE1 = "/authorized1";

export const ROUTE_HOME = "/home";
export const ROUTE_CATEGORIES = "/categories";
export const ROUTE_SHOPPING_LIST = "/shopping-list";
export const ROUTE_PROFILE = "/profile";

export const ROUTE_STORE_REVIEW = "/store-review";

export const ROUTE_ABOUT_US = "/about-us";
export const ROUTE_CONTACT_US = "/contact-us";
export const ROUTE_LOGIN = "/login";
export const ROUTE_REGISTER = "/register";
export const ROUTE_BECOME_VENDOR = "/register-vendor";
export const ROUTE_PRIVACY_POLICY = "/privacy-policy";
export const ROUTE_TERMS_OF_SERVICE = "/terms-of-service";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_INVITE_AGENT = "/invite";


export const MENU_ITEMS = [
    { route: ROUTE_DASHBOARD, icon: <Icon component={dashboardIcon} className="menu-item-icon" />, title: "Overview", type:"sidebar" },
    { route: ROUTE_USER_PROFILE, icon: <UserOutlined className="menu-item-icon" />, title: "User Profile", type:"sidebar" },
    
]


export const GetCurrentMainRoute = (pathname) => {
    if (pathname.startsWith(ROUTE_DASHBOARD)) return ROUTE_DASHBOARD;
  
    
    if (pathname.startsWith(ROUTE_USER_PROFILE)) return ROUTE_USER_PROFILE;
  
  
    return ROUTE_DASHBOARD;
  };