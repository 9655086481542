// @flow strict

import { Modal, Row } from "antd";
import * as React from "react";
import { confirmable } from "react-confirm";
import "./MyAlertDialog.css";
function MyAlertDialog(props) {
  const { show, proceed } = props;
  const { title, content } = props;
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="alert-rec-modal"
      closable={true}
      maskClosable={false}
      title={title}
      footer={null}
    >
      <p className="rec-alert-title">Registration is Currently CLOSED</p>
      <p className="rec-alert-content">Please contact <a href="mailto:mwrecbasketball@gmail.com">mwrecbasketball@gmail.com</a> with any questions</p>
    </Modal>
  );
}

export default confirmable(MyAlertDialog);
