import React from "react";
import "./AuthDialogWidget.css";
import LogoImg from '../../assets/images/mark.png';
import { Link } from "react-router-dom";
function AuthDialogWidget(props) {
  return (
    <div className="auth-dialog-widget">
      <Link to="/">
        <img src={LogoImg} style={{ width: "250px", height: "auto", paddingTop: props.top, marginBottom: 70, cursor: 'pointer' }} />
      </Link>
      <div className={"title"}>
        {props.title}
        <span className="sub_title">{props.sub_title !== undefined && <div>{props.sub_title}</div>}</span>
      </div>
      {props.children}
    </div>
  );
}

export default AuthDialogWidget;
