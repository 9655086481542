// @flow strict

import { Col, message, Row } from "antd";
import AuthDialogWidget from "components/AuthDialogWidget/AuthDialogWidget";
import AuthLogoWidget from "components/AuthLogoWidget/AuthLogoWidget";
import React, { useRef, useState } from "react";
import ReactCodeInput from "react-code-input";
import "./CodeVerificationWidget.css";
import ResendCodeWidget from "./ResendCodeWidget";
import { DoubleLeftOutlined } from "@ant-design/icons";
import TextButton from "components/ButtonWidgets/TextButton/TextButton";
import "components/ButtonWidgets/NormalButton/NormalButton.css";
import { useAuth } from "navigation/Auth/ProvideAuth";
function CodeVerificationWidget(props) {
  const auth = useAuth();
  const { goBackPage, onSuccessLogin } = props;
  const { user_id } = props;
  const [isWrong, setIsWrong] = useState(false);
  const verifyButtonRef = useRef(null);
  const [code, setCode] = useState("");
  // console.log("user_id", user_id);
  const onClickVerification = () => {
    if (code.length !== 6) {
      message.error("Please input 6 digit code.");
      setIsWrong(true);
      return;
    }
    auth
      .activate(user_id, code)
      .then((res) => {
        // console.log("activate res", res);
        onSuccessLogin(res);
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onChangeCode = (value) => {
    setCode(value);
    if (isWrong === true) {
      setIsWrong(false);
    }
    if (value.length === 6) {
      verifyButtonRef.current.focus();
    }
  };

  return (
    <div style={{ color: "var(--blackColor)" }}>
      <Row align={"middle"}>
        <Col xs={24} sm={12} style={{ textAlign: "center" }}>
          <AuthLogoWidget />
        </Col>
        <Col xs={24} sm={12} style={{ textAlign: "center" }}>
          <AuthDialogWidget title={"Verification"}>
            <div className="code-verification-explain">
              Enter the six digit verification code sent to the provided email
              or phone number.
            </div>

            <div style={{ marginTop: 20 }} className={"activation-code-input"}>
              <ReactCodeInput
                value={code}
                isValid={!isWrong}
                fields={6}
                onChange={onChangeCode}
              />
            </div>

            <Row
              align={"middle"}
              justify={"space-between"}
              style={{
                marginTop: 20,
              }}
            >
              <Col>
                <TextButton
                  style={{ color: "var(--blueColor)", fontSize: 24 }}
                  onClick={goBackPage}
                >
                  <DoubleLeftOutlined />
                </TextButton>
              </Col>
              <Col>
                <ResendCodeWidget user_id={user_id} setIsWrong={setIsWrong} />
              </Col>
            </Row>

            <div style={{ textAlign: "center", marginTop: 20 }}>
              <div style={{ width: "70%", margin: "auto" }}>
                <button
                  className="green-button"
                  onClick={onClickVerification}
                  style={{ backgroundColor: "var(--blueColor)" }}
                  ref={verifyButtonRef}
                >
                  Verify
                </button>
              </div>
            </div>
          </AuthDialogWidget>
        </Col>
      </Row>
    </div>
  );
}

export default CodeVerificationWidget;
