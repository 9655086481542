import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import Modal from "antd/lib/modal/Modal";
import "./ForgotPassModal.css";
import { Input, Spin, message } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { ValidateEmail } from "utils/GlobalFunctions";
import {
  apiChangePassword,
  apiConfirmForgotCode,
  apiRequestForgotCode,
} from "services/userServices";
function ForgotPassModal(props) {
  const { show, proceed } = props;
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  const onCreate = () => {
    let param = {};
    proceed(param);
  };
  const onSendMail = () => {
    let tmpErrors = [...errorField];
    if (email == "") {
      tmpErrors = [...tmpErrors, "email"];
    } else {
      if (ValidateEmail(email) === false) {
        tmpErrors = [...tmpErrors, "email"];
      }
    }
    setErrorField(tmpErrors);
    if (tmpErrors.length == 0) {
      setIsBusy(true);
      apiRequestForgotCode(email)
        .then((res) => {
          setIsBusy(false);
          if (res.result) {
            setStep(1);
            message.success(res.msg);
          } else {
            message.error(res.msg);
          }
        })
        .catch((err) => {
          message.error("Something went wrong");
        });
    }
  };
  const onVerify = () => {
    let tmpErrors = [...errorField];
    if (code == "") {
      tmpErrors = [...tmpErrors, "code"];
    }
    setErrorField(tmpErrors);
    if (tmpErrors.length == 0) {
      setIsBusy(true);
      apiConfirmForgotCode(code)
        .then((res) => {
          setIsBusy(false);
          if (res.result) {
            setToken(res.msg);
            setStep(2);
            message.success("Your email is verified successfully");
          } else {
            message.error("Email not found in the BeClutch system");
          }
        })
        .catch((err) => {
          message.error("Something went wrong");
        });
    }
  };
  const onUpdate = () => {
    let tmpErrors = [...errorField];
    if (password == "") {
      tmpErrors = [...tmpErrors, "password"];
    }
    if (confirmPassword == "") {
      tmpErrors = [...tmpErrors, "confirm_pass"];
    }
    if (
      password != "" &&
      confirmPassword != "" &&
      password != confirmPassword
    ) {
      tmpErrors = [...tmpErrors, "confirm_pass"];
    }
    setErrorField(tmpErrors);
    if (tmpErrors.length == 0) {
      setIsBusy(true);
      apiChangePassword(token, password)
        .then((res) => {
          setIsBusy(false);
          if (res.result) {
            message.success("Password changed successfully");
            proceed(false);
          } else {
            message.error("Something went wrong");
          }
        })
        .catch((err) => {
          message.error("Something went wrong");
        });
    }
  };
  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="forgotpass-dlg"
      closable={true}
      maskClosable={false}
      title={"Forgot Password?"}
      okText="Send"
      footer={null}
      onOk={onCreate}
    >
      <Spin spinning={isBusy}>
        {step == 0 && (
          <>
            <div>
              Please enter your email address and we will email you a code to
              reset password
            </div>
            <div style={{ marginTop: 5 }}>
              <Input
                type="email"
                value={email}
                placeholder="Email address"
                className={
                  errorField !== undefined && errorField.includes("email")
                    ? `common-input-error`
                    : `common-input`
                }
                onPressEnter={() => {
                  onSendMail();
                }}
                onChange={(e) => {
                  if (errorField.includes("email")) {
                    let errors = errorField.filter((y) => y != "email");
                    setErrorField([...errors]);
                  }
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div style={{ textAlign: "center", marginTop: 30 }}>
              <CommonButton
                style={{ padding: "8px 20px", width: "100%" }}
                onClick={() => {
                  onSendMail();
                }}
              >
                Request password reset email
              </CommonButton>
            </div>
          </>
        )}
        {step == 1 && (
          <>
            <div style={{ fontWeight: 600 }}>Email: {email}</div>
            <div style={{ marginTop: 10 }}>
              Please check your email for the verification code and enter it
              below
            </div>
            <div style={{ marginTop: 5 }}>
              <Input
                type="text"
                value={code}
                placeholder="Verification Code"
                className={
                  errorField !== undefined && errorField.includes("code")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("code")) {
                    let errors = errorField.filter((y) => y != "code");
                    setErrorField([...errors]);
                  }
                  setCode(e.target.value);
                }}
                onPressEnter={() => {
                  onVerify();
                }}
              />
            </div>
            <div style={{ textAlign: "center", marginTop: 30 }}>
              <CommonButton
                style={{ padding: "8px 105px", width: "100%" }}
                onClick={() => {
                  onVerify();
                }}
              >
                Verify
              </CommonButton>
            </div>
          </>
        )}
        {step == 2 && (
          <>
            <div style={{ fontWeight: 600 }}>Email: {email}</div>
            <div style={{ marginTop: 10 }}>Please enter new password</div>
            <div style={{ marginTop: 5 }}>
              <Input.Password
                placeholder={"New Password"}
                value={password}
                onChange={(e) => {
                  if (errorField.includes("password")) {
                    let errors = errorField.filter((y) => y != "password");
                    setErrorField([...errors]);
                  }
                  setPassword(e.target.value);
                }}
                className={
                  errorField !== undefined && errorField.includes("password")
                    ? `common-input-error`
                    : `common-input`
                }
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <Input.Password
                placeholder={"Confirm Password"}
                value={confirmPassword}
                onChange={(e) => {
                  if (errorField.includes("confirm_pass")) {
                    let errors = errorField.filter((y) => y != "confirm_pass");
                    setErrorField([...errors]);
                  }
                  setConfirmPassword(e.target.value);
                }}
                onPressEnter={() => {
                  onUpdate();
                }}
                className={
                  errorField !== undefined &&
                  errorField.includes("confirm_pass")
                    ? `common-input-error`
                    : `common-input`
                }
              />
            </div>
            <div style={{ textAlign: "center", marginTop: 30 }}>
              <CommonButton
                style={{ padding: "8px 105px", width: "100%" }}
                onClick={() => {
                  onUpdate();
                }}
              >
                Update
              </CommonButton>
            </div>
          </>
        )}
      </Spin>
    </Modal>
  );
}

export default confirmable(ForgotPassModal);
