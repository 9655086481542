import { APP_INFO, MENU_COLLAPSED_CHANGED, SIZE_CHANGED, CURRENT_ROUTE, UNREAD_NOTIFICATION_COUNT, STORE_LIST, PAYTOPROMOTE_LIST, NOPROMOTE_LIST, PRODUCT_BASIC_DATA } from "redux/actions/appActions";

const initialState = {
  width: 1024,
  height: 768,
  collapsed: false,
  currentRoute: '/dashboard'
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIZE_CHANGED:
      return {
        ...state,
        ...action.payload
      }
    case MENU_COLLAPSED_CHANGED:
      return {
        ...state,
        ...action.payload
      }
    case CURRENT_ROUTE:
      return {
        ...state,
        ...action.payload
      }
    case UNREAD_NOTIFICATION_COUNT:
      return {
        ...state,
        ...action.payload
      }
    case STORE_LIST:
      return {
        ...state,
        ...action.payload
      }
    case PAYTOPROMOTE_LIST:
      return {
        ...state,
        ...action.payload
      }
    case NOPROMOTE_LIST:
      return {
        ...state,
        ...action.payload
      }
    case PRODUCT_BASIC_DATA:
      return {
        ...state,
        ...action.payload
      }
    case APP_INFO:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
