import React from "react"
import { useState } from "react";
import { Row, Col } from "antd";
import "./AppSection.css"

import HeroImg from '../../../assets/images/home/1.png';
import GoogleImg from '../../../assets/images/home/app-download-android.png';
import AppleImg from '../../../assets/images/home/app-download-ios.png';
import { urlApplePlayStore, urlGoolePlayStore } from "services/CONSTANTS";


const AppSection = () => {

    const goApple = () => {
        window.open(urlApplePlayStore, "_blank")
    }
    const goGoogle = () => {
        window.open(urlGoolePlayStore, "_blank")
    }

    return (
        <div className="app-section">
            <p className="slide-title">App - iOS & Android</p>
            <Row gutter={32}>
                <Col md={12}>
                    <p className="slide-description">BeClutch was designed with the sole purpose of providing a platform for businesses, clubs and teams to communicate and meet all the needs of a group of any size.</p>

                </Col>
                <Col md={12}>
                    <p className="slide-description">Download the BeClutch app for free from the App store or Google play store and choose the bundle that fits your organizational needs.</p>

                </Col>
            </Row>
            <Row gutter={[32, 16]} style={{ marginTop: 30 }}>
                <Col md={12}>
                    <img src={AppleImg} className="app-img" onClick={()=>{
                        goApple();
                    }} />
                </Col>
                <Col md={12}>
                    <img src={GoogleImg} className="app-img" onClick={()=>{
                        goGoogle();
                    }}/>
                </Col>
            </Row>
        </div>
    );
}

export default AppSection