import React from "react";
import Icon from '@ant-design/icons';
import { Button, Col, Divider, Input, Row } from "antd";
import AuthDialogWidget from "components/AuthDialogWidget/AuthDialogWidget";
import AuthLogoWidget from "components/AuthLogoWidget/AuthLogoWidget";
import NormalButton from "components/ButtonWidgets/NormalButton/NormalButton";
import TextButton from "components/ButtonWidgets/TextButton/TextButton";
import CodeVerificationWidget from "components/CodeVerificationWidget/CodeVerificationWidget";
import InputEmailOrPhone from "components/InputWidgets/InputEmailOrPhone/InputEmailOrPhone";
import InputPassword from "components/InputWidgets/InputPassword/InputPassword";
import { useState } from "react";
import { withRouter } from "react-router";
import "./InputPage.css";
import { ReactComponent as userIcon } from '../../assets/icons/ic_user.svg';
import { ValidateEmail } from "utils/GlobalFunctions";
function InputPage(props) {
  const { userDetail, setUserDetail, isBusy } = props;
  const { onClickLogin, onClickRegister, onClickRegisterVendor } = props;
  const [wrongList, setWrongList] = useState([]);
  const [validFlag, setValidFlag] = useState(false)
  const [email_err_msg, setEmailErrMsg] = useState("")
  const [password_err_msg, setPasswordErrMsg] = useState("")

  const validateFields = () => {
    const { email, name, phone, password, register_with } = userDetail;
    // console.log(email, phone, password, register_with);
    let tmpWrongList = [];
    if (name === undefined || name === "") {
      tmpWrongList.push("name");
      setEmailErrMsg("This field is required")
    } else {
      if (ValidateEmail(name) === false) {
        tmpWrongList.push("name");
        setEmailErrMsg("Not a valid email address")
      }
    }
    if (password === undefined || password === "") {
      tmpWrongList.push("password");
      setPasswordErrMsg("This field is required");
    } else {
      if (password.length < 6) {
        tmpWrongList.push("password");
        setWrongList(tmpWrongList);
        setPasswordErrMsg("Must be at least 6 characters in length");
        return tmpWrongList.length === 0;
      }
      // if (!/[a-z]/.test(password)) {
      //   tmpWrongList.push("password");
      //   setWrongList(tmpWrongList);
      //   setPasswordErrMsg("should contain at least one lower case");
      //   return tmpWrongList.length === 0;
      // }
      // if (!/[A-Z]/.test(password)) {
      //   tmpWrongList.push("password");
      //   setWrongList(tmpWrongList);
      //   setPasswordErrMsg("should contain at least one upper case");
      //   return tmpWrongList.length === 0;
      // }
      // if (!/\d/.test(password)) {
      //   tmpWrongList.push("password");
      //   setWrongList(tmpWrongList);
      //   setPasswordErrMsg("should contain at least one digit");
      //   return tmpWrongList.length === 0;
      // }
      // if (!/[!@#$%^&*]/.test(password)) {
      //   tmpWrongList.push("password");
      //   setWrongList(tmpWrongList);
      //   setPasswordErrMsg("should contain at least one special character");
      //   return tmpWrongList.length === 0;
      // }

    }
    setWrongList(tmpWrongList);
    setValidFlag(tmpWrongList.length === 0 ? false : true)
    return tmpWrongList.length === 0;
  };

  const onClickNext = () => {
    if (validateFields() === false) {
      // console.log("REJECTED");
      return;
    }
    onClickLogin();
  };
  return (
    <div className="main-background-rect" style={{ background: "#ffffff" }}>
      <div style={{ background: "#ffffff", textAlign: 'center' }} className="logo-rects">
        <AuthDialogWidget title={""} top={150}>
          <div className="email-input" style={{ paddingLeft: 50, paddingRight: 50, marginTop: 15 }}>
            <Input
              type="text"
              prefix={<Icon component={userIcon} className="menu-item-icon" />}
              suffix={""}
              placeholder="Email"
              value={userDetail.name === undefined ? "" : userDetail.name}
              onChange={(e) => {
                if (wrongList.includes("name")) {
                  let tmpList = [...wrongList]
                  setWrongList(tmpList.filter((x) => x !== "name"))
                }
                setUserDetail({ ...userDetail, name: e.target.value })
              }
              }
              style={{
                border:
                  wrongList.includes("name") === true
                    ? "1px solid var(--redColor)"
                    : "1px solid var(--greyColor)",
              }}
            />
          </div>
          {wrongList.includes("name") && <div style={{ color: 'rgb(244, 67, 54)', fontSize: 12, paddingTop: 1, textAlign: 'left', paddingLeft: 50 }}>
            {email_err_msg}
          </div>}
          <div style={{ marginTop: 15 }}>
            <InputPassword
              userDetail={userDetail}
              setUserDetail={setUserDetail}
              wrongList={wrongList}
              setWrongList={setWrongList}
              placeholder={"Password"}
              onClick={onClickNext}
              type={"login"}
            />
          </div>
          {wrongList.includes("password") && <div style={{ color: 'rgb(244, 67, 54)', fontSize: 12, paddingTop: 1, textAlign: 'left', paddingLeft: 50 }}>
            {password_err_msg}
          </div>}
          <div style={{ marginTop: 15, paddingLeft: 50, paddingRight: 50 }}>
            <NormalButton
              style={{ backgroundColor: "#7fb522" }}
              onClick={onClickNext}
              isBusy={isBusy}
            >
              Login
            </NormalButton>
          </div>
          <div style={{ textAlign: "center", marginTop: 25, paddingLeft: 20, paddingRight: 20 }}>
            <TextButton
              onClick={onClickRegister}
              style={{ color: '#8abb37' }}
            >
              Register
            </TextButton>
          </div>
        </AuthDialogWidget>
      </div>
    </div>
  );
}

export default withRouter(InputPage);
