import React, { useState } from "react";
import { Row, Col, Input, DatePicker, Divider, Select, BackTop } from "antd";
import moment from "moment";
import "./StepOne.css";
import { EditOutlined, PlusOutlined, UserAddOutlined } from "@ant-design/icons";
import { showImageReadDlg } from "components/ImageReadDlg/showImageReadDlg";
import { useEffect } from "react";
import InputEmailOrPhone from "components/InputWidgets/InputEmailOrPhone/InputEmailOrPhone";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { MaskedInput } from "antd-mask-input";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { capitalizeFirstLetter, isNumber } from "utils/GlobalFunctions";
import { useRef } from "react";

const StepOne = (props) => {
  const { userData, setUserData, errorField, setErrorField } = props;
  const dateFormat = "YYYY-MM-DD";
  const { Option } = Select;
  const onAddMore = () => {
    let tmpList = [...userData.playerInfo];
    let newInfo = {
      first_name: "",
      last_name: "",
      email: "",
      telePhone: "",
      birthday: "",
      gender: "",
      grade: "",
      height: "",
      experience: "",
      t_shirt: "",
      sel_trip: "",
      fee: "",
      fee_label: "",
    };
    tmpList = [...tmpList, newInfo];
    setUserData({
      ...userData,
      playerInfo: tmpList,
    });
  };
  const onDelPlayer = (index) => {
    let tmpList = [...userData.playerInfo];
    tmpList = tmpList.filter((x, k) => k !== index);
    setUserData({
      ...userData,
      playerInfo: tmpList,
    });
  };
  return (
    <div className="profile-info-widget">
      <div>
        <span className="title">Step 1: Enter Participant Info</span>
        <Divider></Divider>
      </div>
      <div className="block">
        {userData.playerInfo.map((x, index) => {
          return (
            <div key={index}>
              <div>
                <Row justify="space-between" style={{ marginTop: 20 }}>
                  <Col>
                    <span className="title">Participant {index + 1} Info</span>
                  </Col>
                  <Col>
                    <span
                      className="title"
                      onClick={() => {
                        onDelPlayer(index);
                      }}
                      style={
                        index == 0 ? { display: "none" } : { cursor: "pointer" }
                      }
                    >
                      Close
                    </span>
                  </Col>
                </Row>
                <Divider
                  style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
                ></Divider>
              </div>
              <div style={{ marginTop: 20 }}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12}>
                    <div style={{ paddingBottom: "5px" }}>First Name*</div>
                    <Input
                      value={x.first_name}
                      className={
                        errorField !== undefined &&
                        errorField.includes(index + "first_name")
                          ? `common-input-error`
                          : `common-input`
                      }
                      onChange={(e) => {
                        if (errorField.includes(index + "first_name")) {
                          let errors = errorField.filter(
                            (y) => y != index + "first_name"
                          );
                          setErrorField([...errors]);
                        }
                        let tmpPlayerList = [...userData.playerInfo];
                        tmpPlayerList[index].first_name = capitalizeFirstLetter(
                          e.target.value
                        );
                        setUserData({
                          ...userData,
                          playerInfo: tmpPlayerList,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <div style={{ paddingBottom: "5px" }}>Last Name*</div>
                    <Input
                      value={x.last_name}
                      className={
                        errorField !== undefined &&
                        errorField.includes(index + "last_name")
                          ? `common-input-error`
                          : `common-input`
                      }
                      onChange={(e) => {
                        if (errorField.includes(index + "last_name")) {
                          let errors = errorField.filter(
                            (y) => y != index + "last_name"
                          );
                          setErrorField([...errors]);
                        }
                        let tmpPlayerList = [...userData.playerInfo];
                        tmpPlayerList[index].last_name = capitalizeFirstLetter(
                          e.target.value
                        );
                        setUserData({
                          ...userData,
                          playerInfo: tmpPlayerList,
                        });
                      }}
                    />
                  </Col>
                </Row>
                {/* <Row gutter={16} style={{ marginTop: 20 }}>
                  <Col xs={24} sm={12}>
                    <div style={{ paddingBottom: "5px" }}>Email*</div>
                    <Input
                      type="email"
                      value={x.email}
                      className={
                        errorField !== undefined &&
                        errorField.includes(index + "email")
                          ? `common-input-error`
                          : `common-input`
                      }
                      onChange={(e) => {
                        if (errorField.includes(index + "email")) {
                          let errors = errorField.filter(
                            (y) => y != index + "email"
                          );
                          setErrorField([...errors]);
                        }
                        let tmpPlayerList = [...userData.playerInfo];
                        tmpPlayerList[index].email = e.target.value;
                        setUserData({
                          ...userData,
                          playerInfo: tmpPlayerList,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <div style={{ paddingBottom: "5px" }}>Telephone*</div>
                    <Input
                      type="text"
                      value={x.telePhone}
                      placeholder="___-___-____"
                      className={
                        errorField !== undefined &&
                        errorField.includes(index + "telePhone")
                          ? `common-input-error`
                          : `common-input`
                      }
                      onChange={(e, w) => {
                        let str = e.target.value;
                        let filStr = str.replace(/[- ]/g, "");
                        let tmpPlayerList = [...userData.playerInfo];

                        if (filStr == "" || filStr.length == 0) {
                          tmpPlayerList[index].telePhone = "";
                          setUserData({
                            ...userData,
                            playerInfo: tmpPlayerList,
                          });
                          return;
                        }
                        if (!isNumber(filStr)) {
                          return;
                        }
                        if (filStr.length > 10) {
                          return;
                        }
                        if (errorField.includes(index + "telePhone")) {
                          let errors = errorField.filter(
                            (y) => y != index + "telePhone"
                          );
                          setErrorField([...errors]);
                        }
                        if (filStr.length > 3 && filStr.length < 7) {
                          str = filStr.slice(0, 3) + "-" + filStr.slice(3);
                        }

                        if (filStr.length > 6) {
                          str =
                            filStr.slice(0, 3) +
                            "-" +
                            filStr.slice(3, 6) +
                            "-" +
                            filStr.slice(6);
                        }

                        tmpPlayerList[index].telePhone = str;
                        setUserData({
                          ...userData,
                          playerInfo: tmpPlayerList,
                        });
                      }}
                    />
                  </Col>
                </Row> */}

                <Row gutter={16} style={{ marginTop: 20 }}>
                  <Col xs={24} sm={12}>
                    <div style={{ paddingBottom: "5px" }}>Birthdate*</div>
                    <DatePicker
                      style={{
                        width: "100%",
                        cursor: "pointer",
                      }}
                      format={dateFormat}
                      allowClear={false}
                      value={
                        x.birthday == "" ? "" : moment(x.birthday, dateFormat)
                      }
                      className={
                        errorField !== undefined &&
                        errorField.includes(index + "birthday")
                          ? `common-input-error`
                          : `common-input`
                      }
                      onChange={(v, str) => {
                        if (errorField.includes(index + "birthday")) {
                          let errors = errorField.filter(
                            (y) => y != index + "birthday"
                          );
                          setErrorField([...errors]);
                        }
                        let tmpPlayerList = [...userData.playerInfo];
                        tmpPlayerList[index].birthday = str;
                        setUserData({
                          ...userData,
                          playerInfo: tmpPlayerList,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <div style={{ paddingBottom: "5px" }}>Gender*</div>
                    <Select
                      value={x.gender == undefined ? null : x.gender}
                      className={
                        errorField !== undefined &&
                        errorField.includes(index + "gender")
                          ? `common-select-error`
                          : `common-select`
                      }
                      onSelect={(v) => {
                        if (errorField.includes(index + "gender")) {
                          let errors = errorField.filter(
                            (y) => y != index + "gender"
                          );
                          setErrorField([...errors]);
                        }
                        let tmpPlayerList = [...userData.playerInfo];
                        tmpPlayerList[index].gender = v;
                        setUserData({
                          ...userData,
                          playerInfo: tmpPlayerList,
                        });
                      }}
                    >
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                    </Select>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: 20 }}>
                  <Col xs={24} sm={12}>
                    <div style={{ paddingBottom: "5px" }}>Grade*</div>
                    <Select
                      value={x.grade == undefined ? null : x.grade}
                      className={
                        errorField !== undefined &&
                        errorField.includes(index + "grade")
                          ? `common-select-error`
                          : `common-select`
                      }
                      onSelect={(v) => {
                        if (errorField.includes(index + "grade")) {
                          let errors = errorField.filter(
                            (y) => y != index + "grade"
                          );
                          setErrorField([...errors]);
                        }
                        let tmpPlayerList = [...userData.playerInfo];
                        tmpPlayerList[index].grade = v;
                        setUserData({
                          ...userData,
                          playerInfo: tmpPlayerList,
                        });
                      }}
                    >
                      <Option value="0">K</Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="6">6</Option>
                      <Option value="7">7</Option>
                      <Option value="8">8</Option>
                      <Option value="9">9</Option>
                      <Option value="10">10</Option>
                      <Option value="11">11</Option>
                      <Option value="12">12</Option>
                    </Select>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div style={{ paddingBottom: "5px" }}>
                      Height (in inches)*
                    </div>
                    <Input
                      type={"number"}
                      value={x.height}
                      className={
                        errorField !== undefined &&
                        errorField.includes(index + "height")
                          ? `common-input-error`
                          : `common-input`
                      }
                      onChange={(e) => {
                        if (errorField.includes(index + "height")) {
                          let errors = errorField.filter(
                            (y) => y != index + "height"
                          );
                          setErrorField([...errors]);
                        }
                        let tmpPlayerList = [...userData.playerInfo];
                        tmpPlayerList[index].height = e.target.value;
                        setUserData({
                          ...userData,
                          playerInfo: tmpPlayerList,
                        });
                      }}
                    />
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: 20 }}>
                  <Col xs={24} sm={12}>
                    <div style={{ paddingBottom: "5px" }}>
                     Highest level of basketball experience*
                    </div>
                    <Select
                      value={x.experience == undefined ? null : x.experience}
                      className={
                        errorField !== undefined &&
                        errorField.includes(index + "experience")
                          ? `common-select-error`
                          : `common-select`
                      }
                      onSelect={(v) => {
                        if (errorField.includes(index + "experience")) {
                          let errors = errorField.filter(
                            (y) => y != index + "experience"
                          );
                          setErrorField([...errors]);
                        }
                        let tmpPlayerList = [...userData.playerInfo];
                        tmpPlayerList[index].experience = v;
                        setUserData({
                          ...userData,
                          playerInfo: tmpPlayerList,
                        });
                      }}
                    >
                      <Option value="REC">REC</Option>
                      <Option value="CYO">CYO</Option>
                      <Option value="AAU">AAU</Option>
                      <Option value="NONE">NONE</Option>
                    </Select>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div style={{ paddingBottom: "5px" }}>T-Shirt Size*</div>
                    <Select
                      value={x.t_shirt == undefined ? null : x.t_shirt}
                      className={
                        errorField !== undefined &&
                        errorField.includes(index + "t_shirt")
                          ? `common-select-error`
                          : `common-select`
                      }
                      onSelect={(v) => {
                        if (errorField.includes(index + "t_shirt")) {
                          let errors = errorField.filter(
                            (y) => y != index + "t_shirt"
                          );
                          setErrorField([...errors]);
                        }
                        let tmpPlayerList = [...userData.playerInfo];
                        tmpPlayerList[index].t_shirt = v;
                        setUserData({
                          ...userData,
                          playerInfo: tmpPlayerList,
                        });
                      }}
                    >
                      <Option value="YS">YS</Option>
                      <Option value="YM">YM</Option>
                      <Option value="YL">YL</Option>
                      <Option value="AS">AS</Option>
                      <Option value="AM">AM</Option>
                      <Option value="AL">AL</Option>
                      <Option value="AXL">AXL</Option>
                    </Select>
                  </Col>
                </Row>
              </div>
            </div>
          );
        })}
        <div style={{ marginTop: 40, textAlign: "center", marginBottom: 10 }}>
          <CommonButton
            customClass="register-next-button"
            onClick={() => {
              onAddMore();
            }}
          >
            Add another Participant
          </CommonButton>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
