import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "navigation/Auth/ProvideAuth";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { updateSubscription, updateUser } from "redux/actions/userActions";
import axios from "axios";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import { Steps, Button, message, Row, Col, Spin, BackTop } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import "./RegisterPage.css";
import { getInitData, isEmpty, ValidateEmail } from "utils/GlobalFunctions";
import { ROOT, ROUTE_WELCOME } from "navigation/CONSTANTS";
import StepInfo from "./StepInfo/StepInfo";
import StepFee from "./StepFee/StepFee";
import StepCheckout from "./StepCheckout/StepCheckout";
import { apiRegisterUser } from "services/registerService";
import { isValidPhoneNumber } from "react-phone-number-input";
import { apiGetRegistrationGroup } from "services/dashboardService";

const { Step } = Steps;

function RegisterPage(props) {
  const location = useLocation();
  const auth = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const userDataStore = useSelector((x) => x.userDataStore);
  const appDataStore = useSelector((x) => x.appDataStore);
  const [isBusy, setIsBusy] = useState(false);
  const [errorField, setErrorField] = useState([]);
  const [feeValid, setFeeValid] = useState(true);

  const [current, setCurrent] = useState(0);
  const [userData, setUserData] = useState({
    playerInfo: [
      {
        first_name: "",
        last_name: "",
        email: "",
        telePhone: "",
        birthday: "",
        gender: "",
        grade: "",
        height: "",
        experience: "",
        t_shirt: "",
        sel_trip: "",
        fee: "",
        fee_label: "",
      },
    ],
    feeList: [],
    fee: 0,
    fee_label: "",
    isPayment: false,
    discountCode: "",
    c_first_name: "",
    c_last_name: "",
    c_email: "",
    c_cell_phone: "",
  });

  const [srcGroupList, setSrcGroupList] = useState([]);
  const [appInfo, setAppInfo] = useState({
    registration_info: null,
    discount_codes: [],
    group_list: [],
    registration_main_list: [],
    waiver_list: []
  });

  useEffect(() => {
    if (appDataStore.appInfo == undefined) {
      history.push(ROOT);
    } else {
      setAppInfo(appDataStore.appInfo);
      console.log("io:", appDataStore.appInfo)
    }

    // getRegGroupInfo(appDataStore.appInfo?.registration_info?.ID);
    initGlobalVal();
  }, []);
  const initGlobalVal = () => {
    const step = localStorage.getItem("basketball_step");
    if (step != null) {
      setCurrent(parseInt(step));
    }

    let tmpList = [...appDataStore.appInfo.group_list];
    let tmpSrcList = tmpList.map((x, index) => {
      return {
        id: x.ID,
        title: x.registration_group_short_name,
        description: x.registration_group_name,
        price: x.registration_group_fee,
        checked: false,
        discount_available: x.discount_available,
        open: x.open_close,
        max_registrations: x.max_registrations,
        current_registrations: x.current_registrations
      };
    });
    setSrcGroupList(tmpSrcList);
    let tmpUserData = userData;
    const saved_user_info = localStorage.getItem("basketball_player_info");
    if (saved_user_info != null) {
      let tmpObj = JSON.parse(saved_user_info);
      let savedUserInfo = getInitData();
      tmpUserData = {
        ...userData,
        ...savedUserInfo,
      };
    }

    setUserData({ ...tmpUserData, feeList: [...tmpSrcList] });

  };

  const getRegGroupInfo = (regId) => {
    const saved_user_info = localStorage.getItem("basketball_player_info");
    setIsBusy(true);
    console.log("id:", regId)
    apiGetRegistrationGroup(regId)
      .then((res) => {
        setIsBusy(false);
        if (res.data != null && res.data.length > 0) {
          let tmpList = [...res.data];
          let tmpSrcList = tmpList.map((x, index) => {
            return {
              id: x.ID,
              title: x.registration_group_short_name,
              description: x.registration_group_name,
              price: x.registration_group_fee,
              checked: false,
              discount_available: x.discount_available,
            };
          });
          setSrcGroupList(tmpSrcList);
          let tmpUserData = userData;

          if (saved_user_info != null) {
            let tmpObj = JSON.parse(saved_user_info);
            let savedUserInfo = getInitData();
            tmpUserData = {
              ...userData,
              ...savedUserInfo,
            };
          }

          setUserData({ ...tmpUserData, feeList: [...tmpSrcList] });
        } else {
          setSrcGroupList([]);
        }
      })
      .catch((err) => {
        setIsBusy(false);
        message.error("Something went wrong");
      });
  };
  const next = () => {
    if (current == 0) {
      if (!validateOneFields()) return;
      saveLocalData(current + 1);
      setCurrent(current + 1);
      window.scrollTo(0, 0);
    } else if (current == 1) {
      if (!validateTwoFields()) return;
      saveLocalData(current + 1);
      setCurrent(current + 1);
      window.scrollTo(0, 0);
    } else if (current == 2) {
      if (!validateThreeFields()) return;
      onPurchase();
    }
  };

  const saveLocalData = (cur) => {
    localStorage.setItem("basketball_player_info", JSON.stringify(userData));
    localStorage.setItem("basketball_step", cur);
  };
  const prev = () => {
    if (current == 0) {
      history.goBack();
    } else {
      setCurrent(current - 1);
    }
  };

  const onPurchase = (
    isPayment,
    payment_method_id,
    customer_id,
    payment_method,
    stax_user_id
  ) => {
    let param = { ...userData };
    param = {
      ...param,
      registration_id: appInfo.registration_info?.ID,
      registration_name: appInfo.registration_info?.registration_name,
      isPayment,
      payment_method_id,
      customer_id,
      payment_method,
      stax_user_id,
    };
    setIsBusy(true);
    apiRegisterUser(param)
      .then((res) => {
        setIsBusy(false);
        if (res.result) {
          message.success("Welcome MW REC BASKETBALL!");
          localStorage.removeItem("basketball_player_info");
          localStorage.removeItem("basketball_step");
          setTimeout(() => {
            history.replace(ROUTE_WELCOME);
          }, 300);
        } else {
          message.error(res.msg);
        }
      })
      .catch((err) => {
        setIsBusy(false);
        message.error("Something went wrong");
      });
  };

  const steps = [
    {
      title: "Participant Info",
      subtitle: "Please enter participant info below.",
      content: "",
    },
    {
      title: "Participant Fees",
      subtitle: "Please select the fee(s) that apply to each participant.",
      content: "",
    },
    {
      title: "Checkout",
      subtitle:
        "Please reivew your registration fees and select a payment method.",
      content: "",
    },
  ];

  const validateOneFields = () => {
    var errorList = Array();

    let tmpList = [...userData.playerInfo];
    tmpList.map((x, index) => {
      errorList = isEmpty(x, "first_name", errorList, index);
      errorList = isEmpty(x, "last_name", errorList, index);
      errorList = isEmpty(x, "birthday", errorList, index);
      errorList = isEmpty(x, "gender", errorList, index);
      errorList = isEmpty(x, "grade", errorList, index);
      errorList = isEmpty(x, "height", errorList, index);
      errorList = isEmpty(x, "experience", errorList, index);
      errorList = isEmpty(x, "t_shirt", errorList, index);

      // let emailErrList = [];
      // emailErrList = isEmpty(x, "email", emailErrList, index);
      // if (emailErrList.length == 0) {
      //   if (ValidateEmail(x.email) === false) {
      //     errorList = [...errorList, index + "email"];
      //   }
      // } else {
      //   errorList = [...errorList, ...emailErrList];
      // }

      // if (x.telePhone != undefined && x.telePhone != "") {
      //   if (x.telePhone.length != 12 || x.telePhone.includes("_")) {
      //     errorList = [...errorList, index + "telePhone"];
      //   }
      // } else {
      //   errorList = [...errorList, index + "telePhone"];
      // }
    });

    errorList = isEmpty(userData, "address", errorList);
    errorList = isEmpty(userData, "city", errorList);
    errorList = isEmpty(userData, "state", errorList);
    errorList = isEmpty(userData, "postal_code", errorList);
    errorList = isEmpty(userData, "p_first_name", errorList);

    // let pErrList = [];
    // pErrList = isEmpty(userData, "p_home_phone", pErrList);
    // if (pErrList.length == 0) {
    //   if (userData.p_home_phone.toString().length != 12) {
    //     errorList.push("p_home_phone");
    //   }
    // } else {
    //   errorList = [...errorList, ...pErrList];
    // }

    if (
      userData.p_home_phone != undefined &&
      userData.p_home_phone != "___-___-____" &&
      userData.p_home_phone != ""
    ) {
      if (userData.p_home_phone.length != 12) {
        errorList.push("p_home_phone" || userData.p_home_phone.includes("_"));
      }
    } else {
      errorList.push("p_home_phone");
    }

    errorList = isEmpty(userData, "p_last_name", errorList);

    if (
      userData.p_cell_phone != undefined &&
      userData.p_cell_phone != "___-___-____" &&
      userData.p_cell_phone != ""
    ) {
      if (
        userData.p_cell_phone.length != 12 ||
        userData.p_cell_phone.includes("_")
      ) {
        errorList.push("p_cell_phone");
      }
    } else {
      errorList.push("p_cell_phone");
    }

    errorList = isEmpty(userData, "p_relation", errorList);
    let p_emailErrList = [];
    p_emailErrList = isEmpty(userData, "p_email", p_emailErrList);
    if (p_emailErrList.length == 0) {
      if (ValidateEmail(userData.p_email) === false) {
        errorList.push("p_email");
      }
    } else {
      errorList = [...errorList, ...p_emailErrList];
    }
    errorList = isEmpty(userData, "p_adult", errorList);

    if (
      userData.c_email != undefined &&
      userData.c_email !== "null" &&
      userData.c_email != ""
    ) {
      if (ValidateEmail(userData.c_email) === false) {
        errorList.push("c_email");
      }
    }
    if (
      userData.c_cell_phone != undefined &&
      userData.c_cell_phone != "___-___-____" &&
      userData.c_cell_phone != ""
    ) {
      if (userData.c_cell_phone.length != 12) {
        errorList.push("c_cell_phone");
      }
    }
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const validateTwoFields = () => {
    let valid = true;
    if (userData.playerInfo.length == 0) {
      valid = false;
    } else {
      let tmpList = [...userData.playerInfo];
      for (let i = 0; i < tmpList.length; i++) {
        let item = tmpList[i];
        if (item.sel_trip != "") {
          valid = true;
        } else {
          valid = false;
          break;
        }
      }
    }
    setFeeValid(valid);
    if (!valid) {
      message.error("Please select fee");
    }
    return valid;
  };
  const validateThreeFields = () => {
    if (!userData.isPayment) {
      message.error("Payment Not Completed");
    }
    return userData.isPayment;
  };
  const onBack = () => {
    localStorage.removeItem("basketball_player_info");
    localStorage.removeItem("basketball_step");
    if (userDataStore.token !== undefined && userDataStore.token != "") {
      history.goBack();
    } else {
      history.push(ROOT);
    }
  };

  return (
    <div className="register-page">
      <Spin spinning={isBusy}>
        <CommonDivWidget>
          <div style={{ position: "absolute" }}>
            <img
              src="/assets/images/ic_back.png"
              style={{ width: 22, margin: "5px 0px", cursor: "pointer" }}
              onClick={() => {
                onBack();
              }}
            />
          </div>
          <div className="title">MW REC BASKETBALL REGISTRATION</div>
          <div className="title">{steps[current].title}</div>
          <div className="sub-title">{steps[current].subtitle}</div>
          <Steps current={current} className="step">
            {steps.map((item) => (
              <Step key={item.title} />
            ))}
          </Steps>
          <div className="steps-content">
            {current == 0 && (
              <StepInfo
                userData={userData}
                setUserData={setUserData}
                errorField={errorField}
                setErrorField={setErrorField}
                onNext={next}
              />
            )}
            {current == 1 && (
              <StepFee
                userData={userData}
                setUserData={setUserData}
                errorField={errorField}
                setErrorField={setErrorField}
                srcGroupList={srcGroupList}
                onNext={next}
              />
            )}
            {current == 2 && (
              <StepCheckout
                userData={userData}
                setUserData={setUserData}
                errorField={errorField}
                setErrorField={setErrorField}
                onNext={next}
                onRegister={onPurchase}
              />
            )}
          </div>
          <div className="steps-action">
            {errorField.length != 0 && (
              <div className="valid-rect">
                <span>Correct errors in red above before proceeding</span>
              </div>
            )}
            {!feeValid && (
              <div className="valid-rect">
                <span>Please select fee</span>
              </div>
            )}
            {current == 0 && (
              <div>
                <Row justify="center">
                  <CommonButton
                    customClass="register-next-button"
                    onClick={() => next()}
                  >
                    Next
                  </CommonButton>
                </Row>
                <Row justify="center" style={{ marginTop: 10 }}>
                  <CommonButton
                    customClass="register-cancel-button"
                    onClick={() => prev()}
                  >
                    Cancel
                  </CommonButton>
                </Row>
              </div>
            )}
            {current == 1 && (
              <Row>
                <Col flex="180" style={{ marginTop: 25 }}>
                  <CommonButton
                    customClass="register-prev-button"
                    onClick={() => prev()}
                  >
                    Previous
                  </CommonButton>
                </Col>
                <Col flex="auto" style={{ marginTop: 25 }}>
                  <CommonButton
                    customClass="register-next-button"
                    onClick={() => next()}
                  >
                    Next
                  </CommonButton>
                </Col>
              </Row>
            )}
            {current == 2 && (
              <Row justify="center">
                <Col style={{ marginTop: 25 }}>
                  <CommonButton
                    customClass="register-prev-button"
                    onClick={() => prev()}
                  >
                    Previous
                  </CommonButton>
                </Col>
              </Row>
            )}
          </div>
          <BackTop />
        </CommonDivWidget>
      </Spin>
    </div>
  );
}

export default RegisterPage;
