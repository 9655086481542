import React, { useEffect, useState, useRef } from "react";
import {
  Divider,
  Row,
  Col,
  Spin,
  Tabs,
  Input,
  Radio,
  message,
  Checkbox,
  Alert,
  Select,
} from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import PaymentMethodItem from "./PaymentMethodItem";
import moment from "moment";
import "./StepCheckout.css";
import CardPayment from "./CardPayment";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { getInitData, isEmpty } from "utils/GlobalFunctions";
import BankPayment from "./BankPayment";
import NormalButton from "components/ButtonWidgets/NormalButton/NormalButton";
import { useDispatch, useSelector } from "react-redux";

import {
  apiRegisterStaxUser,
  apiRegisterStaxUserUpdate,
} from "services/registerService";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
const { TabPane } = Tabs;
const { Option } = Select;
const StepCheckout = (props) => {
  const { userData, setUserData, errorField, setErrorField, onRegister } =
    props;
  const [itemList, setItemList] = useState([]);
  const [isCardPayment, setIsCardPayment] = useState(true);
  const [isBusy, setIsBusy] = useState(false);
  const [isPayButtonDisabled, setIsPayButtonDisabled] = useState(true);
  const [isConfirm, setIsConfirm] = useState(false);
  const [disCount, setDisCount] = useState("");
  const [codeValid, setCodeValid] = useState(false);
  const [apply, setApply] = useState(-1);
  const codeList = ["THANKYOU2024", "EARLYBIRD2024"];
  const staxJs = useRef(null);

  const appDataStore = useSelector((x) => x.appDataStore);

  useEffect(() => {
    // fattmerchantdemo

    const StaxJs = window.StaxJs;

    staxJs.current = new StaxJs(process.env.REACT_APP_FM_KEY, {
      number: {
        id: "card-number",
        placeholder: "0000 0000 0000 0000",
        style:
          "width: 100%; height:100%; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em; font-size: 91%; box-sizing: border-box;",
      },
      cvv: {
        id: "card-cvv",
        placeholder: "CVV",
        style:
          "width: 100%; height:100%; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em; font-size: 91%; box-sizing: border-box;",
      },
    });

    showCardForm();

    staxJs.current.on("card_form_complete", (message) => {
      // activate pay button
      setIsPayButtonDisabled(false);
    });

    staxJs.current.on("card_form_uncomplete", (message) => {
      // deactivate pay button
      setIsPayButtonDisabled(true);
    });

    let tmpList = [];
    tmpList.push({ id: 0, image: "/assets/images/card1.png", checked: true });
    tmpList.push({ id: 1, image: "/assets/images/card2.png", checked: false });
    setItemList(tmpList);
    calcFee(-1);
  }, []);

  const calcFee = (discount_value) => {
    if (userData !== undefined) {
      let tmpList = [...userData.playerInfo];
      if (tmpList.length > 0 && tmpList[0].first_name == "") {
        const saved_user_info = localStorage.getItem("basketball_player_info");
        if (saved_user_info != null) {
          let savedUserInfo = getInitData();
          tmpList = [...savedUserInfo.playerInfo];
        }
      }
      let fee_label = "";
      let fee_val = 0;
      let multipleDiscount = 0;
      for (let i = 0; i < tmpList.length; i++) {
        let item = tmpList[i];
        let tmpFeeVal = parseFloat(item.fee);
        if (i == 1) {
          tmpFeeVal = tmpFeeVal - 25;
          multipleDiscount += 25;
        }
        if (i > 1) {
          tmpFeeVal = tmpFeeVal - 50;
          multipleDiscount += 50;
        }
        fee_val = fee_val + tmpFeeVal;
        if (i == 0) {
          fee_label = item.fee_label;
        }
      }
      let total_card = 0;
      let total_bank = 0;
      let src_total_card = 0;
      let src_total_bank = 0;
      let processing_card_fee = parseFloat(fee_val * 0.0365).toFixed(2);
      let processing_bank_fee = parseFloat(fee_val * 0.0265).toFixed(2);
      total_card = parseFloat(
        parseFloat(fee_val) + parseFloat(processing_card_fee)
      ).toFixed(2);
      total_bank = parseFloat(
        parseFloat(fee_val) + parseFloat(processing_bank_fee)
      ).toFixed(2);
      src_total_card = total_card;
      src_total_bank = total_bank;
      if (discount_value != -1) {
        if (discount_value == 0) {
          total_card = 0;
          total_bank = 0;
        } else {
          total_card = src_total_card - discount_value;
          total_bank = src_total_bank - discount_value;
        }
      }
      setUserData({
        ...userData,
        fee: parseFloat(fee_val).toFixed(2),
        processing_card_fee: processing_card_fee,
        processing_bank_fee: processing_bank_fee,
        total_card: parseFloat(total_card).toFixed(2),
        total_bank: parseFloat(total_bank).toFixed(2),
        src_total_card: parseFloat(src_total_card).toFixed(2),
        src_total_bank: parseFloat(src_total_bank).toFixed(2),
        discountCode: disCount,
        multiple_dc: multipleDiscount,
      });
      window.scrollTo(0, 0);
    }
  };

  const showCardForm = () =>
    staxJs.current
      .showCardForm()
      .then((handler) => { })
      .catch((err) => {
        console.log("error init form " + err);
      });
  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(userData, "bill_address", errorList);
    errorList = isEmpty(userData, "bill_city", errorList);
    errorList = isEmpty(userData, "bill_state", errorList);
    errorList = isEmpty(userData, "bill_postal_code", errorList);
    if (isCardPayment) {
      errorList = isEmpty(userData, "expiration", errorList);
    } else {
      errorList = isEmpty(userData, "bank_name", errorList);
      errorList = isEmpty(userData, "bank_type", errorList);
      errorList = isEmpty(userData, "bank_holder_type", errorList);
      errorList = isEmpty(userData, "bank_account", errorList);
      errorList = isEmpty(userData, "bank_routing", errorList);
    }
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const onPay = async () => {
    if (isBusy) {
      return;
    }
    if (!validateFields()) {
      message.error("Please confirm payment method information");
      return;
    }
    if (isCardPayment && isPayButtonDisabled) {
      message.error("Please input valid payment information");
      return;
    }
    if (!isConfirm) {
      return;
    }
    // let dlgRes = await showConfirmDlgWidget({
    //   title:
    //     "Registration closed December 1st. However, once your payment is processed you will be contacted regarding placement on an existing team",
    //   content: "Would you like to submit your registration at this time?",
    //   className: "add",
    // });
    // if (dlgRes == null) return;
    // if (dlgRes) {

    // }
    onPaymentCall();
    // if (userData.discount_available == "0") {
    //   onPaymentCall();
    // } else {
    //   if (apply == -1 || disCount != "WAITLIST") {
    //     if (apply == -1 && disCount != "") {
    //       message.error("Please apply discount code");
    //     } else {

    //     }
    //   } else {
    //   }
    // }
  };

  const onPaymentCall = () => {
    if (userData.total_card == 0) {
      // message.success("Payment Successful");
      setUserData({
        ...userData,
        isPayment: true,
        payment_method_id: "",
        customer_id: "",
        payment_method: isCardPayment,
      });
      setTimeout(() => {
        onRegister(true, "", "", isCardPayment, "");
      }, 500);
    } else {
      if (userData.isPayment) {
        message.success("Payment has been already done successfully");
        setUserData({
          ...userData,
          isPayment: true,
          payment_method_id: "",
          customer_id: "",
          payment_method: isCardPayment,
        });
        setTimeout(() => {
          onRegister(true, "", "", isCardPayment, "");
        }, 500);
      }
      if (!validateFields()) {
        message.error("Please confirm payment method information");
        return;
      }
      if (isCardPayment && isPayButtonDisabled) {
        message.error("Please input valid payment information");
        return;
      }

      let param = { ...userData };
      param = {
        ...param,
        isPayment: false,
        payment_method_id: "",
        customer_id: "",
        payment_method: isCardPayment,
        registration_id: appDataStore?.appInfo.registration_info?.ID,
        registration_name: appDataStore?.appInfo.registration_info?.registration_name,
      };

      setIsBusy(true);
      apiRegisterStaxUser(param)
        .then((res) => {
          onStaxCall(res.data);
        })
        .catch((err) => {
          setIsBusy(false);
          message.error("Something went wrong in stax log script");
        });
    }
  };
  const onStaxCall = (stax_user_id) => {
    var extraDetails = {
      total: isCardPayment
        ? parseFloat(userData.total_card).toFixed(2)
        : parseFloat(userData.total_bank).toFixed(2),
      firstname: userData.p_first_name,
      lastname: userData.p_last_name,
      email: userData.p_email,
      phone: userData.p_home_phone,
      address_1: userData.bill_address,
      address_city: userData.bill_city,
      address_state: userData.bill_state,
      address_zip: userData.bill_postal_code,
      address_country: "USA",
      send_receipt: true,
      url: "https://app.staxpayments.com/#/bill/",
      ...(isCardPayment
        ? {
          method: "card",
          month: moment(userData.expiration, "MM/YY").format("MM"),
          year: moment(userData.expiration, "MM/YY").format("YYYY"),
        }
        : {
          method: "bank",
          bank_name: userData.bank_name,
          bank_type: userData.bank_type,
          bank_holder_type: userData.bank_holder_type,
          bank_account: userData.bank_account,
          bank_routing: userData.bank_routing,
          person_name: userData.first_name + " " + userData.last_name,
        }),
      validate: false,
      meta: {
        reference: "MWRECBASKETBALL-" + userData.fee_label,
        isCCPaymentEnabled: true,
        isACHPaymentEnabled: true,
        isTipEnabled: true,
      },
    };

    staxJs.current
      .pay(extraDetails)
      .then(async (payResult) => {
        const { payment_method_id, customer_id } = payResult;
        message.success("Payment Successful");
        setUserData({
          ...userData,
          isPayment: true,
          payment_method_id,
          customer_id,
          payment_method: isCardPayment,
        });
        setTimeout(() => {
          setIsBusy(false);
          onRegister(
            true,
            payment_method_id,
            customer_id,
            isCardPayment,
            stax_user_id
          );
        }, 500);
      })
      .catch((err) => {
        message.error("Payment Failed");
        onStaxFailUpdate(stax_user_id, JSON.stringify(err));
        setIsBusy(false);
      });
  };
  const onStaxFailUpdate = (stax_user_id, msg) => {
    let param = {
      stax_user_id,
      msg,
    };

    setIsBusy(true);
    apiRegisterStaxUserUpdate(param)
      .then((res) => { })
      .catch((err) => { });
  };
  const onCheckMethod = (e) => {
    if (e.target.value) {
      setIsCardPayment(true);
      setUserData({ ...userData, isCardPayment: true });
    } else {
      setIsCardPayment(false);
      setUserData({ ...userData, isCardPayment: false });
    }
  };
  const setConfirm = (e) => {
    setIsConfirm(e.target.checked);
  };
  const onApply = () => {
    if (disCount != "") {
      let inValid = true;
      for (let i = 0; i < codeList.length; i++) {
        if (disCount == codeList[i]) {
          inValid = false;
          break;
        } else {
          inValid = true;
          continue;
        }
      }
      setCodeValid(inValid);
      if (!inValid) {
        let val = "";
        if (disCount == codeList[0]) {
          if (isCardPayment) {
            val = userData.src_total_card;
          } else {
            val = userData.src_total_bank;
          }
        }
        setApply(val);
        calcFee(val);
      }
    }
  };
  return (
    <div className="profile-info-widget">
      <div>
        <span className="title">MW REC 2023-2024 Fees</span>
        <Divider></Divider>
      </div>
      <div className="block">
        <span className="title">Participant Fees</span>
        <Divider
          style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
        ></Divider>
        {userData.playerInfo.map((playerInfo, index) => {
          return (
            <Row
              key={index}
              style={{
                marginTop: 10,
                paddingLeft: 20,
                paddingTop: 10,
                paddingBottom: 5,
              }}
            >
              <Col flex={"200px"}>
                {playerInfo.first_name + ` ` + playerInfo.last_name}
              </Col>
              <Col flex={"auto"}>{playerInfo.fee_label}</Col>
              <Col flex={"180px"}>${playerInfo.fee}</Col>
            </Row>
          );
        })}
        {/* <div style={{ marginTop: 20, paddingLeft: 20, paddingTop: 10, paddingBottom: 10 }}>
                    <UserAddOutlined style={{ color: 'var(--mainColor)', fontSize: 16 }}></UserAddOutlined>
                    <span className="title" style={{ paddingLeft: 10, color: 'var(--mainColor)' }}>Add Another Participant</span>
                </div> */}
      </div>
      {/* <div className="block">
                <span className="title">Total Fees</span>
                <Divider style={{ borderTop: '1px solid rgb(151, 196, 217, 71%)' }}></Divider>
                <Row style={{ marginTop: 20, paddingLeft: 20, paddingTop: 10, paddingBottom: 10 }}>
                    <Col flex={"auto"}>Participant Fees</Col>
                    <Col flex={"180px"}>${userData.fee}</Col>
                </Row>
                <Row style={{ marginTop: 20, paddingLeft: 20, paddingTop: 10, paddingBottom: 10 }}>
                    <Col flex={"auto"}>Form Fees</Col>
                    <Col flex={"180px"}>$0</Col>
                </Row>
                <Row style={{ marginTop: 20, background: 'var(--mainColor)', padding: 20, color: 'var(--whiteColor)' }}>
                    <Col flex={"auto"}>Total</Col>
                    <Col flex={"180px"} style={{ paddingLeft: 20, fontSize: 16 }}>${userData.fee}</Col>
                </Row>
            </div> */}
      <div className="block">
        {/* <Row>
          <Col xs={24} sm={24}>
            <div className="alert-info-rect">
              We offer a discount for Registering your 2nd, 3rd, or 4th child.
              Please read the registration email for details or email us at{" "}
              <a href="mailto:mwrecbasketball@gmail.com">
                mwrecbasketball@gmail.com
              </a>
            </div>
          </Col>
        </Row> */}
        <Row
          justify="space-between"
          align={"bottom"}
          gutter={[64, 8]}
          className="discount-rect"
        >
          <Col xs={24} sm={12}>
            <div style={{ paddingBottom: "5px" }}>Discount Code</div>
            <Input
              value={disCount}
              className={`common-input`}
              onChange={(e) => {
                setDisCount(e.target.value);
              }}
            />
            <div className="discount-valid">
              {codeValid && <span>Error: Discountcode Invalid</span>}
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <CommonButton
              style={
                disCount != ""
                  ? {
                    backgroundColor: "#7fb522",
                    cursor: "pointer",
                    lineHeight: "85px",
                  }
                  : {
                    backgroundColor: "#878787",
                    cursor: "no-drop",
                    lineHeight: "85px",
                  }
              }
              onClick={() => onApply()}
            >
              Apply
            </CommonButton>
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: 30 }}>
        <span className="title">Confirm Payment and Finish Checkout</span>
        <Divider></Divider>
        <div>
          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Billing Address*</div>
              <Input
                value={userData.bill_address}
                className={
                  errorField !== undefined &&
                    errorField.includes("bill_address")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("bill_address")) {
                    let errors = errorField.filter((x) => x != "bill_address");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, bill_address: e.target.value });
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Billing City*</div>
              <Input
                value={userData.bill_city}
                className={
                  errorField !== undefined && errorField.includes("bill_city")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("bill_city")) {
                    let errors = errorField.filter((x) => x != "bill_city");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, bill_city: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>
                Billing State/Province*
              </div>
              <Select
                showSearch
                className={
                  errorField !== undefined && errorField.includes("bill_state")
                    ? `common-select-error`
                    : `common-select`
                }
                value={
                  userData.bill_state == undefined ? null : userData.bill_state
                }
                onSelect={(v) => {
                  if (errorField.includes("bill_state")) {
                    let errors = errorField.filter((x) => x != "bill_state");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, bill_state: v });
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="AL">Alabama</Option>
                <Option value="AK">Alaska</Option>
                <Option value="AZ">Arizona</Option>
                <Option value="AR">Arkansas</Option>
                <Option value="CA">California</Option>
                <Option value="CO">Colorado</Option>
                <Option value="CT">Connecticut</Option>
                <Option value="DE">Delaware</Option>
                <Option value="FL">Florida</Option>
                <Option value="GA">Georgia</Option>
                <Option value="HI">Hawaii</Option>
                <Option value="ID">Idaho</Option>
                <Option value="IL">Illinois</Option>
                <Option value="IN">Indiana</Option>
                <Option value="IA">Iowa</Option>
                <Option value="KS">Kansas</Option>
                <Option value="KY">Kentucky</Option>
                <Option value="LA">Louisiana</Option>
                <Option value="ME">Maine</Option>
                <Option value="MD">Maryland</Option>
                <Option value="MA">Massachusetts</Option>
                <Option value="MI">Michigan</Option>
                <Option value="MN">Minnesota</Option>
                <Option value="MS">Mississippi</Option>
                <Option value="MO">Missouri</Option>
                <Option value="MT">Montana</Option>
                <Option value="NE">Nebraska</Option>
                <Option value="NV">Nevada</Option>
                <Option value="NH">New Hampshire</Option>
                <Option value="NJ">New Jersey</Option>
                <Option value="NM">New Mexico</Option>
                <Option value="NY">New York</Option>
                <Option value="NC">North Carolina</Option>
                <Option value="ND">North Dakota</Option>
                <Option value="OH">Ohio</Option>
                <Option value="OK">Oklahoma</Option>
                <Option value="OR">Oregon</Option>
                <Option value="PA">Pennsylvania</Option>
                <Option value="RI">Rhode Island</Option>
                <Option value="SC">South Carolina</Option>
                <Option value="SD">South Dakota</Option>
                <Option value="TN">Tennessee</Option>
                <Option value="TX">Texas</Option>
                <Option value="UT">Utah</Option>
                <Option value="VT">Vermont</Option>
                <Option value="VA">Virginia</Option>
                <Option value="WA">Washington</Option>
                <Option value="WV">West Virginia</Option>
                <Option value="WI">Wisconsin</Option>
                <Option value="WY">Wyoming</Option>
              </Select>
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>
                Billing Zip/Postal Code*
              </div>
              <Input
                value={userData.bill_postal_code}
                className={
                  errorField !== undefined &&
                    errorField.includes("bill_postal_code")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("bill_postal_code")) {
                    let errors = errorField.filter(
                      (x) => x != "bill_postal_code"
                    );
                    setErrorField([...errors]);
                  }
                  setUserData({
                    ...userData,
                    bill_postal_code: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: 20, marginLeft: 20 }}>
          <span
            style={{ fontWeight: 400 }}
          >{`Credit Card (VISA, Mastercard, AMEX, Discover)`}</span>
        </div>
      </div>
      <div className="block">
        {isCardPayment && (
          <CardPayment
            userData={userData}
            setUserData={setUserData}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        )}
        {!isCardPayment && (
          <BankPayment
            userData={userData}
            setUserData={setUserData}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        )}
      </div>
      <div style={{ marginTop: 30 }}>
        <span className="title">Order Summary</span>
        <Divider></Divider>
      </div>
      <div className="block">
        <Row
          style={{
            marginTop: 10,
            paddingLeft: 20,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <Col flex={"auto"}>Registration Fees</Col>
          <Col flex={"180px"}>
            ${parseFloat(userData.fee) + parseFloat(userData.multiple_dc)}
          </Col>
        </Row>
        {apply != -1 ? (
          <Row
            style={{
              marginTop: 20,
              paddingLeft: 20,
              paddingTop: 10,
              paddingBottom: 10,
              color: "var(--redColor)",
            }}
          >
            <Col flex={"auto"}>Multiplayer Discount</Col>
            <Col flex={"185px"}>-${apply}</Col>
          </Row>
        ) : (
          <Row
            style={{
              marginTop: 20,
              paddingLeft: 20,
              paddingTop: 10,
              paddingBottom: 10,
              color: "var(--redColor)",
            }}
          >
            <Col flex={"auto"}>Multiplayer Discount</Col>
            <Col flex={"185px"}>
              -${userData.multiple_dc == undefined ? 0 : userData.multiple_dc}
            </Col>
          </Row>
        )}
        <Row
          style={{
            marginTop: 20,
            paddingLeft: 20,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <Col flex={"auto"}>Processing Fees</Col>
          <Col flex={"180px"}>
            $
            {isCardPayment
              ? userData.processing_card_fee
              : userData.processing_bank_fee}
          </Col>
        </Row>

        <Row
          style={{
            marginTop: 20,
            background: "var(--mainColor)",
            padding: 20,
            color: "var(--whiteColor)",
          }}
        >
          <Col flex={"auto"}>Checkout Total</Col>
          <Col flex={"180px"} style={{ paddingLeft: 20, fontSize: 16 }}>
            ${isCardPayment ? userData.total_card : userData.total_bank}
          </Col>
        </Row>
        <div style={{ marginTop: 50, marginBottom: 20, fontSize: 24 }}>
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <Checkbox
                checked={isConfirm}
                className="pay-check"
                onChange={setConfirm}
              >
                This charge will appear under the name of BECLUTCH on your
                credit/debit card statement.
              </Checkbox>
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: 0, marginBottom: 30, textAlign: "center" }}>
          <NormalButton
            style={
              isConfirm
                ? { backgroundColor: "#7fb522", cursor: "pointer" }
                : { backgroundColor: "#878787", cursor: "no-drop" }
            }
            onClick={onPay}
            isBusy={isBusy}
          >
            Pay ${isCardPayment ? userData.total_card : userData.total_bank}
          </NormalButton>
        </div>
      </div>
    </div>
  );
};

export default StepCheckout;
