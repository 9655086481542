import { Input } from "antd";
import Icon from '@ant-design/icons';
import React from "react";
import { ReactComponent as lockIcon } from '../../../assets/icons/ic_lock.svg';
import "./InputPassword.css"; 
function InputPassword(props) {
  const { userDetail, setUserDetail, placeholder, onClick, type } = props;
  const { wrongList, setWrongList } = props;
  return (
    <div className="input-password">
      <Input.Password
        placeholder={placeholder}
        prefix={<Icon component={lockIcon} className="menu-item-icon" />}
        value={placeholder == "Confirm Password" ? (userDetail.confirm_password === undefined ? "" : userDetail.confirm_password) : (userDetail.password === undefined ? "" : userDetail.password)}
        onChange={(e) => {
          if (placeholder == "Confirm Password" && wrongList.includes("confirm_password")) {
            let tmpList = [...wrongList]
            setWrongList(tmpList.filter((x) => x !== "confirm_password"))
          }
          if (placeholder !== "Confirm Password" && wrongList.includes("password")) {
            let tmpList = [...wrongList]
            setWrongList(tmpList.filter((x) => x !== "password"))
          }
          if (placeholder == "Confirm Password") {
            setUserDetail({ ...userDetail, confirm_password: e.target.value })
          } else {
            setUserDetail({ ...userDetail, password: e.target.value })
          }
        }

        }
        onPressEnter={(e) => {
          if (placeholder == "Confirm Password") {
            setUserDetail({ ...userDetail, confirm_password: e.target.value })
          } else {
            setUserDetail({ ...userDetail, password: e.target.value })
            if (type !== undefined) {
              onClick()
            }

          }
        }
        }
        className={
          wrongList.includes("password") === true
            ? "phone-input-password-wrong"
            : "phone-input-password-correct"
        }
      />
    </div>
  );
}

export default InputPassword;
