import axios from "axios";
import { BASE_URL } from "./CONSTANTS";

const mainAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
});

function errorHandler(error) {
  const response = error?.response;

  if (response && response?.status === 401) {
    return Promise.reject(new Error("Unauthorized"));
  }

  if (response && response?.status) {
    return Promise.reject(response);
  }

  return Promise.reject(error);
}

mainAxios.interceptors.request.use(
  (defaultConfig) => defaultConfig,
  (error) => Promise.reject(error)
);

mainAxios.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error)
);

export default mainAxios;
