import { googleKey } from "config/CONSTANTS";
export const GET_ALL_USERS = () => `/data/users`; ////// will remove soon
export const GET_USER_DETAILS = (id) => `/data/user`; ////// will remove soon


export const BASE_URL = `${process.env.REACT_APP_API}`;

export const urlRegisterUser = BASE_URL + "mwuser/register_user";
export const urlUserLogin = BASE_URL + "main/login";
export const urlDashboardDetail = BASE_URL + "dashboard/index";
export const urlGetProfileInfo = "";
export const urlUpdateProfileInfo = "";

export const urlGoolePlayStore = "https://play.google.com/store/apps/details?id=com.rock.beclutch";
export const urlApplePlayStore = "https://apps.apple.com/us/app/id1501441735";


