import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlRegisterUser } from "./CONSTANTS";
import api from "./api.js";
export const apiRegisterStaxUser = async (param) => {
  const params = new URLSearchParams();
  let players = [...param.playerInfo];
  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    let tmpFeeVal = parseFloat(player.fee);
    let dc_amount = 0;
    if (i == 1) {
      tmpFeeVal = tmpFeeVal - 25;
      dc_amount = 25;
    }
    if (i > 1) {
      tmpFeeVal = tmpFeeVal - 50;
      dc_amount = 50;
    }
    let processing_card_fee = parseFloat(tmpFeeVal * 0.0365).toFixed(2);
    let processing_bank_fee = parseFloat(tmpFeeVal * 0.0265).toFixed(2);
    let total_card = parseFloat(
      parseFloat(tmpFeeVal) + parseFloat(processing_card_fee)
    ).toFixed(2);
    let total_bank = parseFloat(
      parseFloat(tmpFeeVal) + parseFloat(processing_bank_fee)
    ).toFixed(2);

    if (param.discountCode == "THANKYOU2024" || param.discountCode == "EARLYBIRD2024") {
      total_card = 0;
      total_bank = 0;
    }
    player.processing_fee = processing_card_fee;
    player.total_fee = total_card;
    player.dc_amount = dc_amount;
    players[i] = player;
  }
  params.append("player_info", JSON.stringify(players));
  params.append("registration_id", param.registration_id);
  params.append("registration_name", param.registration_name);
  params.append("address", param.address);
  params.append("city", param.city);
  params.append("state", param.state);
  params.append("postal_code", param.postal_code);
  params.append("bill_address", param.bill_address);
  params.append("bill_city", param.bill_city);
  params.append("bill_state", param.bill_state);
  params.append("bill_postal_code", param.bill_postal_code);
  params.append("p_first_name", param.p_first_name);
  params.append("p_home_phone", param.p_home_phone);
  params.append("p_last_name", param.p_last_name);
  params.append("p_cell_phone", param.p_cell_phone);
  params.append("p_relation", param.p_relation);
  params.append("p_email", param.p_email);
  params.append("p_adult", param.p_adult);
  params.append("c_first_name", param.c_first_name);
  params.append("c_last_name", param.c_last_name);
  params.append("c_email", param.c_email);
  params.append("c_cell_phone", param.c_cell_phone);
  params.append("discountCode", param.discountCode);
  params.append("payment_method", param.payment_method ? "card" : "bank");
  params.append("payment_method_id", param.payment_method_id);
  params.append("customer_id", param.customer_id);
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post("mwuser/register_stax_user", params, config);

  return data;
};

export const apiRegisterStaxUserUpdate = async (param) => {
  const params = new URLSearchParams();
  params.append("stax_user_id", param.stax_user_id);
  params.append("msg", param.msg);
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post(
    "mwuser/register_stax_user_update",
    params,
    config
  );

  return data;
};

export const apiRegisterUser = async (param) => {
  const params = new URLSearchParams();
  let players = [...param.playerInfo];
  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    let tmpFeeVal = parseFloat(player.fee);
    let dc_amount = 0;
    if (i == 1) {
      tmpFeeVal = tmpFeeVal - 25;
      dc_amount = 25;
    }
    if (i > 1) {
      tmpFeeVal = tmpFeeVal - 50;
      dc_amount = 50;
    }
    let processing_card_fee = parseFloat(tmpFeeVal * 0.0365).toFixed(2);
    let processing_bank_fee = parseFloat(tmpFeeVal * 0.0265).toFixed(2);
    let total_card = parseFloat(
      parseFloat(tmpFeeVal) + parseFloat(processing_card_fee)
    ).toFixed(2);
    let total_bank = parseFloat(
      parseFloat(tmpFeeVal) + parseFloat(processing_bank_fee)
    ).toFixed(2);

    if (param.discountCode == "THANKYOU2024" || param.discountCode == "EARLYBIRD2024") {
      total_card = 0;
      total_bank = 0;
    }
    player.processing_fee = processing_card_fee;
    player.total_fee = total_card;
    player.dc_amount = dc_amount;
    players[i] = player;
  }
  params.append("player_info", JSON.stringify(players));

  params.append("registration_id", param.registration_id);
  params.append("registration_name", param.registration_name);
  params.append("address", param.address);
  params.append("city", param.city);
  params.append("state", param.state);
  params.append("postal_code", param.postal_code);
  params.append("bill_address", param.bill_address);
  params.append("bill_city", param.bill_city);
  params.append("bill_state", param.bill_state);
  params.append("bill_postal_code", param.bill_postal_code);
  params.append("p_first_name", param.p_first_name);
  params.append("p_home_phone", param.p_home_phone);
  params.append("p_last_name", param.p_last_name);
  params.append("p_cell_phone", param.p_cell_phone);
  params.append("p_relation", param.p_relation);
  params.append("p_email", param.p_email);
  params.append("p_adult", param.p_adult);
  params.append("c_first_name", param.c_first_name);
  params.append("c_last_name", param.c_last_name);
  params.append("c_email", param.c_email);
  params.append("c_cell_phone", param.c_cell_phone);
  params.append("discountCode", param.discountCode);
  params.append("payment_method", param.payment_method ? "card" : "bank");
  params.append("payment_method_id", param.payment_method_id);
  params.append("customer_id", param.customer_id);
  params.append("stax_user_id", param.stax_user_id);

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post("mwuser/register_user", params, config);

  return data;
};
