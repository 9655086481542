import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { NotFound } from "navigation/NotFound";
import { BackTop } from "antd";
import {
  ROUTE_LOGIN,
  ROUTE_DASHBOARD,
  ROUTE_USER_PROFILE,
  ROOT,
  ROUTE_REGISTER,
  ROUTE_HOME,
  ROUTE_WELCOME,
} from "navigation/CONSTANTS";
import PrivateRoute from "./Auth/PrivateRoute";
import LoginPage from "pages/LoginPage/LoginPage";
import RegisterPage from "pages/RegisterPage/RegisterPage";
import TopbarLayout from "layouts/TopbarLayout/TopbarLayout";
import DashboardPage from "pages/DashboardPage/Dashboard";
import SidebarLayout from "layouts/SidebarLayout/SidebarLayout";
import { useDispatch } from "react-redux";
import { ChangeSize } from "redux/actions/appActions";
import MainLayout from "layouts/MainLayout/MainLayout";
import UserProfilePage from "pages/ProfilePage/UserProfile/UserProfilePage";
import HomePage from "pages/HomePage/HomePage";
import WelcomePage from "pages/WelcomePage/WelcomePage";
export const RouterConfig = () => {
  const screenSize = useWindowSize();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      ChangeSize({ width: screenSize.width, height: screenSize.height })
    );
  }, [screenSize]);

  return (
    <div>
      <Switch>
        <Route exact path={ROOT} component={HomePage} />
        <Route exact path={ROUTE_REGISTER} component={RegisterPage} />
        <Route exact path={ROUTE_WELCOME} component={WelcomePage} />
        <PrivateRoute exact path={ROUTE_DASHBOARD} component={DashboardPage} />
        <Route path="*" component={NotFound} />
        {/* <SidebarLayout>
          <TopbarLayout>
            <MainLayout>
              <Switch>
                <PrivateRoute
                  exact path={ROOT}
                  component={DashboardPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_DASHBOARD}
                  component={DashboardPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_USER_PROFILE}
                  component={UserProfilePage}
                />
                <Route path="*" component={NotFound} />
              </Switch>
              <BackTop />
            </MainLayout>
          </TopbarLayout>
        </SidebarLayout> */}
      </Switch>
    </div>
  );
};

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
