import api from "./api.js";
export const apiLogin = async (userDetail) => {
  const params = new URLSearchParams();
  params.append("email", userDetail.email);
  params.append("pwd", userDetail.password);

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post("auth/login", params, config);

  return data;
};
export const apiRequestForgotCode = async (email) => {
  const params = new URLSearchParams();
  params.append("email", email);
  params.append("type", "mwrec");

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post("auth/password_reset_email", params, config);

  return data;
};
export const apiConfirmForgotCode = async (code) => {
  const params = new URLSearchParams();
  params.append("verify_code", code);

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post(
    "auth/password_reset_confirm",
    params,
    config
  );

  return data;
};
export const apiChangePassword = async (token, password) => {
  const params = new URLSearchParams();
  params.append("token", token);
  params.append("new_pass", password);

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post("auth/change_password", params, config);

  return data;
};
