import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Divider, message, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { UpdateCurrentRoot } from "redux/actions/appActions";
import { ROUTE_DASHBOARD, ROUTE_REGISTER } from "navigation/CONSTANTS";
import { apiGetMemberList } from "services/dashboardService";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import ClubTable from "./ClubTable/ClubTable";
import "./Dashboard.css";
import SearchWidget from "./SearchWidget/SearchWidget";
import TopbarLayout from "layouts/TopbarLayout/TopbarLayout";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
const Dashboard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userDataStore = useSelector((x) => x.userDataStore);
  const [isBusy, setIsBusy] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [srcList, setSrcList] = useState([]);
  const [selRows, setSelRows] = useState([]);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_DASHBOARD }));
    if (
      userDataStore.EMAIL != undefined &&
      userDataStore.EMAIL != "" &&
      userDataStore.token != undefined &&
      userDataStore.token != ""
    ) {
      getData();
    }
  }, []);

  const showEmptyDlg = async () => {
    let dlgRes = await showConfirmDlgWidget({
      title: "No previous registrations found",
      content: "Would you like to start a new one now?",
      className: "add",
    });
    if (dlgRes == null) return;
    if (dlgRes) {
      onRegisterNew();
    }
  };
  const getData = () => {
    apiGetMemberList(userDataStore.EMAIL, userDataStore.token)
      .then((res) => {
        if (res.result) {
          let tmpList = [...res.data];
          setDataList(tmpList);
          setSrcList(tmpList);
          if (tmpList.length == 0) {
            showEmptyDlg();
          }
        } else {
          setDataList([]);
          setSrcList([]);
          message.error(res.msg);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
      });
  };

  const onSearch = (v) => {
    setSearchKey(v);
    let tmpList = [...srcList];
    if (v == "") {
      setDataList(tmpList);
    } else {
      setDataList(
        tmpList.filter((x) => {
          if (
            x.first_name.toLowerCase().includes(v.toLowerCase()) ||
            x.last_name.toLowerCase().includes(v.toLowerCase())
          ) {
            return x;
          }
        })
      );
    }
  };
  const onRegister = () => {
    let userData = {
      playerInfo: [
        {
          first_name: "",
          last_name: "",
          email: "",
          telePhone: "",
          birthday: "",
          gender: "",
          grade: "",
          height: "",
          experience: "",
          t_shirt: "",
          sel_trip: "",
          fee: "",
          fee_label: "",
        },
      ],
      feeList: [],
      fee: 0,
      fee_label: "",
      isPayment: false,
      discountCode: "",
      c_first_name: "",
      c_last_name: "",
      c_email: "",
      c_cell_phone: "",
    };
    let tmpList = [];
    selRows.map((x) => {
      tmpList = [
        ...tmpList,
        {
          first_name: x.first_name,
          last_name: x.last_name,
          email: "",
          telePhone: "",
          birthday: x.birthday,
          gender: x.gender,
          grade: "",
          height: x.height,
          experience: x.experience,
          t_shirt: "",
          sel_trip: "",
          fee: "",
          fee_label: "",
        },
      ];
    });
    userData = {
      ...userData,
      playerInfo: tmpList,
      p_home_phone: selRows[0].p_home_phone,
      p_adult: "",
      p_cell_phone: selRows[0].p_cell_phone,
      p_email: selRows[0].p_email,
      p_first_name: selRows[0].p_first_name,
      p_last_name: selRows[0].p_last_name,
      p_relation: selRows[0].p_relation,
      address: selRows[0].address,
      c_cell_phone: selRows[0].c_cell_phone,
      c_email: selRows[0].c_email,
      c_first_name: selRows[0].c_first_name,
      c_last_name: selRows[0].c_last_name,
      city: selRows[0].city,
      postal_code: selRows[0].zip,
      state: selRows[0].state,
    };
    localStorage.setItem("basketball_player_info", JSON.stringify(userData));
    setTimeout(() => {
      history.push(ROUTE_REGISTER);
    }, 500);
  };
  const onRegisterNew = () => {
    localStorage.removeItem("basketball_player_info");
    setTimeout(() => {
      history.push(ROUTE_REGISTER);
    }, 500);
  };
  const onClear = () => {
    setSearchKey("");
    setDataList(dataList);
  };
  return (
    <div>
      <TopbarLayout />
      <div className="dashboard_page">
        <CommonDivWidget>
          <Spin spinning={isBusy}>
            <Divider
              style={{ borderTop: "4px solid var(--mainColor)" }}
            ></Divider>
            <SearchWidget
              dataList={dataList}
              selRows={selRows}
              onRegister={onRegister}
              onRegisterNew={onRegisterNew}
              onSearch={onSearch}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
            />
            <ClubTable
              dataList={dataList}
              onRegister={onRegister}
              setSelRows={setSelRows}
            />
          </Spin>
        </CommonDivWidget>
      </div>
    </div>
  );
};

export default Dashboard;
