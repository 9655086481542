import { axiosGet } from "./ajaxServices";
import { urlDashboardDetail } from "./CONSTANTS";
import api from "./api.js"
export const apiGetRegistrationInfo = async (domain) => {
  const params = new URLSearchParams();
  params.append("domain", domain);
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post("admin/get_registration_info", params, config);

  return data;
};
export const apiGetRegistrationGroup = async (regId) => {
  const params = new URLSearchParams();
  params.append("registration_id", regId);
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post("mwuser/get_registration_group", params, config);

  return data;
};

export const apiGetMemberList = async (email, token) => {
  const params = new URLSearchParams();
  params.append("token", token);
  params.append("mail", email);

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post("mwuser/get_registration_member", params, config);

  return data;
};
