import React from "react";
import { Form, Row, Col, DatePicker } from "antd";
import moment from "moment";
const CardPayment = (props) => {
  const { userData, setUserData, errorField, setErrorField } = props
  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24}>
          <div style={{ paddingBottom: "5px" }}>Card Number*</div>
          <div id="card-number" style={{ height: 33 }} />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 10 }}>
        <Col xs={24} sm={12}>
          <div style={{ paddingBottom: "5px" }}>Expiration*</div>
          <DatePicker format="MM/YY" picker="month"
            style={{
              width: "100%",
              cursor: "pointer",
            }}
            className={
              errorField !== undefined && errorField.includes("expiration")
                ? `common-input-error`
                : `common-input`
            }
            onChange={(v, str) => {
              let newErrList = errorField.filter(
                (x, index) => x != "expiration"
              );
              setErrorField([...newErrList]);
              setUserData({ ...userData, expiration: str });
            }}
          />
        </Col>
        <Col xs={24} sm={12}>
          <div style={{ paddingBottom: "5px" }}>CVV</div>
          <div id="card-cvv" style={{ height: 33 }} />
        </Col>
      </Row>
    </>
  );
};

export default CardPayment;
