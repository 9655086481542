import React, { useState } from "react";
import { Row, Col, Input, Divider, Select } from "antd";
import "./StepTwo.css";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { MaskedInput } from "antd-mask-input";
import { capitalizeFirstLetter, isNumber } from "utils/GlobalFunctions";
const StepTwo = (props) => {
  const { userData, setUserData, errorField, setErrorField } = props;

  const { Option } = Select;
  return (
    <div className="profile-info-widget">
      <div>
        <span className="title">Step 2: Enter Parent/Guardian Info</span>
        <Divider></Divider>
      </div>
      <div className="block">
        <div>
          <Row justify="space-between">
            <Col>
              <span className="title">Parent/Guardian</span>
            </Col>
            <Col>
              <span
                className="title"
                style={{ cursor: "pointer", display: "none" }}
              >
                Close
              </span>
            </Col>
          </Row>
          <Divider
            style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
          ></Divider>
        </div>
        <div style={{ marginTop: 20 }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>
                Parent/Guardian First Name*
              </div>
              <Input
                value={userData.p_first_name}
                className={
                  errorField !== undefined &&
                  errorField.includes("p_first_name")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("p_first_name")) {
                    let errors = errorField.filter((x) => x != "p_first_name");
                    setErrorField([...errors]);
                  }
                  setUserData({
                    ...userData,
                    p_first_name: capitalizeFirstLetter(e.target.value),
                  });
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>
                Parent/Guardian Last Name*
              </div>
              <Input
                value={userData.p_last_name}
                className={
                  errorField !== undefined && errorField.includes("p_last_name")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("p_last_name")) {
                    let errors = errorField.filter((x) => x != "p_last_name");
                    setErrorField([...errors]);
                  }
                  setUserData({
                    ...userData,
                    p_last_name: capitalizeFirstLetter(e.target.value),
                  });
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>
                Parent/Guardian Home Phone*
              </div>
              {/* <MaskedInput
                mask={"000-000-0000"}
                value={homePhone}
                className={
                  errorField !== undefined &&
                  errorField.includes("p_home_phone")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("p_home_phone")) {
                    let errors = errorField.filter((x) => x != "p_home_phone");
                    setErrorField([...errors]);
                  }
                  setHomephone(e.target.value);
                }}
              /> */}
              <Input
                type="text"
                value={userData.p_home_phone}
                placeholder="___-___-____"
                className={
                  errorField !== undefined &&
                  errorField.includes("p_home_phone")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e, w) => {
                  let str = e.target.value;
                  let filStr = str.replace(/[- ]/g, "");
                  let tmpPhone = "";
                  if (filStr == "" || filStr.length == 0) {
                    setUserData({
                      ...userData,
                      p_home_phone: "",
                    });
                    return;
                  }
                  if (!isNumber(filStr)) {
                    return;
                  }
                  if (filStr.length > 10) {
                    return;
                  }
                  if (errorField.includes("p_home_phone")) {
                    let errors = errorField.filter((y) => y != "p_home_phone");
                    setErrorField([...errors]);
                  }
                  if (filStr.length > 3 && filStr.length < 7) {
                    str = filStr.slice(0, 3) + "-" + filStr.slice(3);
                  }

                  if (filStr.length > 6) {
                    str =
                      filStr.slice(0, 3) +
                      "-" +
                      filStr.slice(3, 6) +
                      "-" +
                      filStr.slice(6);
                  }
                  setUserData({
                    ...userData,
                    p_home_phone: str,
                  });
                }}
              />
            </Col>

            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>
                Parent/Guardian Cell Phone*
              </div>
              <Input
                type="text"
                value={userData.p_cell_phone}
                placeholder="___-___-____"
                className={
                  errorField !== undefined &&
                  errorField.includes("p_cell_phone")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e, w) => {
                  let str = e.target.value;
                  let filStr = str.replace(/[- ]/g, "");
                  let tmpPhone = "";
                  if (filStr == "" || filStr.length == 0) {
                    setUserData({
                      ...userData,
                      p_cell_phone: "",
                    });
                    return;
                  }
                  if (!isNumber(filStr)) {
                    return;
                  }
                  if (filStr.length > 10) {
                    return;
                  }
                  if (errorField.includes("p_cell_phone")) {
                    let errors = errorField.filter((y) => y != "p_cell_phone");
                    setErrorField([...errors]);
                  }
                  if (filStr.length > 3 && filStr.length < 7) {
                    str = filStr.slice(0, 3) + "-" + filStr.slice(3);
                  }

                  if (filStr.length > 6) {
                    str =
                      filStr.slice(0, 3) +
                      "-" +
                      filStr.slice(3, 6) +
                      "-" +
                      filStr.slice(6);
                  }
                  setUserData({
                    ...userData,
                    p_cell_phone: str,
                  });
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Parent/Guardian Email*</div>
              <Input
                type={"email"}
                value={userData.p_email}
                className={
                  errorField !== undefined && errorField.includes("p_email")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("p_email")) {
                    let errors = errorField.filter((x) => x != "p_email");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, p_email: e.target.value });
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>
                Parent/Guardian Relation*
              </div>
              <Input
                value={userData.p_relation}
                className={
                  errorField !== undefined && errorField.includes("p_relation")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("p_relation")) {
                    let errors = errorField.filter((x) => x != "p_relation");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, p_relation: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Address*</div>
              <Input
                value={userData.address}
                className={
                  errorField !== undefined && errorField.includes("address")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("address")) {
                    let errors = errorField.filter((x) => x != "address");
                    setErrorField([...errors]);
                  }
                  setUserData({
                    ...userData,
                    address: e.target.value,
                    bill_address: e.target.value,
                  });
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>City*</div>
              <Input
                value={userData.city}
                className={
                  errorField !== undefined && errorField.includes("city")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("city")) {
                    let errors = errorField.filter((x) => x != "city");
                    setErrorField([...errors]);
                  }
                  setUserData({
                    ...userData,
                    city: e.target.value,
                    bill_city: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>State/Province*</div>
              <Select
                showSearch
                className={
                  errorField !== undefined && errorField.includes("state")
                    ? `common-select-error`
                    : `common-select`
                }
                value={userData.state == undefined ? null : userData.state}
                onSelect={(v) => {
                  if (errorField.includes("state")) {
                    let errors = errorField.filter((x) => x != "state");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, state: v, bill_state: v });
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="AL">Alabama</Option>
                <Option value="AK">Alaska</Option>
                <Option value="AZ">Arizona</Option>
                <Option value="AR">Arkansas</Option>
                <Option value="CA">California</Option>
                <Option value="CO">Colorado</Option>
                <Option value="CT">Connecticut</Option>
                <Option value="DE">Delaware</Option>
                <Option value="FL">Florida</Option>
                <Option value="GA">Georgia</Option>
                <Option value="HI">Hawaii</Option>
                <Option value="ID">Idaho</Option>
                <Option value="IL">Illinois</Option>
                <Option value="IN">Indiana</Option>
                <Option value="IA">Iowa</Option>
                <Option value="KS">Kansas</Option>
                <Option value="KY">Kentucky</Option>
                <Option value="LA">Louisiana</Option>
                <Option value="ME">Maine</Option>
                <Option value="MD">Maryland</Option>
                <Option value="MA">Massachusetts</Option>
                <Option value="MI">Michigan</Option>
                <Option value="MN">Minnesota</Option>
                <Option value="MS">Mississippi</Option>
                <Option value="MO">Missouri</Option>
                <Option value="MT">Montana</Option>
                <Option value="NE">Nebraska</Option>
                <Option value="NV">Nevada</Option>
                <Option value="NH">New Hampshire</Option>
                <Option value="NJ">New Jersey</Option>
                <Option value="NM">New Mexico</Option>
                <Option value="NY">New York</Option>
                <Option value="NC">North Carolina</Option>
                <Option value="ND">North Dakota</Option>
                <Option value="OH">Ohio</Option>
                <Option value="OK">Oklahoma</Option>
                <Option value="OR">Oregon</Option>
                <Option value="PA">Pennsylvania</Option>
                <Option value="RI">Rhode Island</Option>
                <Option value="SC">South Carolina</Option>
                <Option value="SD">South Dakota</Option>
                <Option value="TN">Tennessee</Option>
                <Option value="TX">Texas</Option>
                <Option value="UT">Utah</Option>
                <Option value="VT">Vermont</Option>
                <Option value="VA">Virginia</Option>
                <Option value="WA">Washington</Option>
                <Option value="WV">West Virginia</Option>
                <Option value="WI">Wisconsin</Option>
                <Option value="WY">Wyoming</Option>
              </Select>
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Zip/Postal Code*</div>
              <Input
                value={userData.postal_code}
                className={
                  errorField !== undefined && errorField.includes("postal_code")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("postal_code")) {
                    let errors = errorField.filter((x) => x != "postal_code");
                    setErrorField([...errors]);
                  }
                  setUserData({
                    ...userData,
                    postal_code: e.target.value,
                    bill_postal_code: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      {/* <div className="block" style={{ marginTop: 40 }}>
                <div>
                    <Row justify='space-between'>
                        <Col>
                            <span className="title">Additional Info</span>
                        </Col>
                    </Row>
                    <Divider style={{ borderTop: '1px solid rgb(151, 196, 217, 71%)' }}></Divider>
                </div>
                <div style={{ marginTop: 20 }}>
                    
                </div>
            </div> */}

      <div className="block" style={{ marginTop: 40 }}>
        <div>
          <Row justify="space-between">
            <Col>
              <span className="title">Additional Parent Info</span>
            </Col>
            <Col>
              <span
                className="title"
                style={{ cursor: "pointer", display: "none" }}
              >
                Close
              </span>
            </Col>
          </Row>
          <Divider
            style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
          ></Divider>
        </div>
        <div style={{ marginTop: 20 }}>
          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>
                Adult interested in Coaching?*
              </div>
              <Input
                value={userData.p_adult}
                className={
                  errorField !== undefined && errorField.includes("p_adult")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("p_adult")) {
                    let errors = errorField.filter((x) => x != "p_adult");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, p_adult: e.target.value });
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className="block" style={{ marginTop: 40, marginBottom: 30 }}>
        <div>
          <Row justify="space-between">
            <Col>
              <span className="title">Additional Emergency Contact</span>
            </Col>
            <Col>
              <span
                className="title"
                style={{ cursor: "pointer", display: "none" }}
              >
                Close
              </span>
            </Col>
          </Row>
          <Divider
            style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
          ></Divider>
        </div>
        <div style={{ marginTop: 20 }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Contact First Name</div>
              <Input
                value={userData.c_first_name}
                className={
                  errorField !== undefined &&
                  errorField.includes("c_first_name")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("c_first_name")) {
                    let errors = errorField.filter((x) => x != "c_first_name");
                    setErrorField([...errors]);
                  }
                  setUserData({
                    ...userData,
                    c_first_name: capitalizeFirstLetter(e.target.value),
                  });
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Contact Last Name</div>
              <Input
                value={userData.c_last_name}
                className={
                  errorField !== undefined && errorField.includes("c_last_name")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("c_last_name")) {
                    let errors = errorField.filter((x) => x != "c_last_name");
                    setErrorField([...errors]);
                  }
                  setUserData({
                    ...userData,
                    c_last_name: capitalizeFirstLetter(e.target.value),
                  });
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Contact Email</div>
              <Input
                type="email"
                value={userData.c_email}
                className={
                  errorField !== undefined && errorField.includes("c_email")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e) => {
                  if (errorField.includes("c_email")) {
                    let errors = errorField.filter((x) => x != "c_email");
                    setErrorField([...errors]);
                  }
                  setUserData({ ...userData, c_email: e.target.value });
                }}
              />
            </Col>

            <Col xs={24} sm={12}>
              <div style={{ paddingBottom: "5px" }}>Contact Phone Number</div>
              <Input
                type="text"
                value={userData.c_cell_phone}
                placeholder="___-___-____"
                className={
                  errorField !== undefined &&
                  errorField.includes("c_cell_phone")
                    ? `common-input-error`
                    : `common-input`
                }
                onChange={(e, w) => {
                  let str = e.target.value;
                  let filStr = str.replace(/[- ]/g, "");
                  let tmpPhone = "";
                  if (filStr == "" || filStr.length == 0) {
                    setUserData({
                      ...userData,
                      c_cell_phone: "",
                    });
                    return;
                  }
                  if (!isNumber(filStr)) {
                    return;
                  }
                  if (filStr.length > 10) {
                    return;
                  }
                  if (errorField.includes("c_cell_phone")) {
                    let errors = errorField.filter((y) => y != "c_cell_phone");
                    setErrorField([...errors]);
                  }
                  if (filStr.length > 3 && filStr.length < 7) {
                    str = filStr.slice(0, 3) + "-" + filStr.slice(3);
                  }

                  if (filStr.length > 6) {
                    str =
                      filStr.slice(0, 3) +
                      "-" +
                      filStr.slice(3, 6) +
                      "-" +
                      filStr.slice(6);
                  }
                  setUserData({
                    ...userData,
                    c_cell_phone: str,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
