export const isEmpty = (list, field, errorList, index = undefined) => {
  if (list[field] === undefined || list[field] === "" || list[field] === " ") {
    let res = [...errorList];
    if (!errorList.includes(field)) {
      res =
        index == undefined
          ? [...errorList, field]
          : [...errorList, index + field];
    }
    return res;
  }
  return errorList;
};

export const isEmptyOneField = (x, field, errorList, index = undefined) => {
  if (
    x === undefined ||
    x === "" ||
    x === " " ||
    x?.name == undefined ||
    x?.name == ""
  ) {
    var res = [...errorList];
    if (!errorList.includes(field)) {
      var res =
        index == undefined
          ? [...errorList, field]
          : [...errorList, index + field];
    }
    return res;
  }
  return errorList;
};

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const ValidateEmail = (email) => {
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  if (!pattern.test(email)) {
    return false;
  } else {
    return true;
  }
};

export const ValidPhone = (str) => {
  var isphone =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(str);
  return isphone;
};
export const numberWithCommas = (x) => {
  // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (x == undefined || x == null || x == 0 || x == "0.00") {
    return "0.00";
  } else {
    return Number(parseFloat(x).toFixed(2)).toLocaleString("en", {
      minimumFractionDigits: 2,
    });
  }
};
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export function isNumber(n) {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
}
export const getInitData = () => {
  const saved_user_info = localStorage.getItem("basketball_player_info");
  if (saved_user_info != null) {
    let tmpObj = JSON.parse(saved_user_info);
    let obj = {
      playerInfo:
        tmpObj.playerInfo == undefined
          ? [
              {
                first_name: "",
                last_name: "",
                email: "",
                telePhone: "",
                birthday: "",
                gender: "",
                grade: "",
                height: "",
                experience: "",
                t_shirt: "",
                sel_trip: "",
                fee: "",
                fee_label: "",
              },
            ]
          : tmpObj.playerInfo,
      p_home_phone: tmpObj.p_home_phone,
      p_adult: tmpObj.p_adult,
      p_cell_phone: tmpObj.p_cell_phone,
      p_email: tmpObj.p_email,
      p_first_name: tmpObj.p_first_name,
      p_last_name: tmpObj.p_last_name,
      p_relation: tmpObj.p_relation,
      address: tmpObj.address,
      bill_address: tmpObj.bill_address,
      bill_city: tmpObj.bill_city,
      bill_postal_code: tmpObj.bill_postal_code,
      bill_state: tmpObj.bill_state,
      c_cell_phone: tmpObj.c_cell_phone,
      c_email: tmpObj.c_email,
      c_first_name: tmpObj.c_first_name,
      c_last_name: tmpObj.c_last_name,
      city: tmpObj.city,
      discountCode: tmpObj.discountCode,
      discount_available: tmpObj.discount_available,
      fee: tmpObj.fee,
      feeList: tmpObj.feeList == undefined ? [] : tmpObj.feeList,
      fee_label: tmpObj.fee_label,
      isPayment: tmpObj.isPayment,
      last_name: tmpObj.last_name,
      postal_code: tmpObj.postal_code,
      state: tmpObj.state,
      isPayment: tmpObj.isPayment == undefined ? "" : tmpObj.isPayment,
      payment_method_id:
        tmpObj.payment_method_id == undefined ? "" : tmpObj.payment_method_id,
      customer_id: tmpObj.customer_id == undefined ? "" : tmpObj.customer_id,
      payment_method:
        tmpObj.payment_method == undefined ? "" : tmpObj.payment_method,
    };
    let tmpList = [...obj.playerInfo];
    let fee_label = "";
    let fee_val = 0;
    for (let i = 0; i < tmpList.length; i++) {
      let item = tmpList[i];
      fee_val = fee_val + parseInt(item.fee);
      if (i == 0) {
        fee_label = item.fee_label;
      }
    }
    let total_card = 0;
    let total_bank = 0;
    let src_total_card = 0;
    let src_total_bank = 0;
    let processing_card_fee = parseFloat(fee_val * 0.0365).toFixed(2);
    let processing_bank_fee = parseFloat(fee_val * 0.0265).toFixed(2);
    total_card = parseFloat(
      parseFloat(fee_val) + parseFloat(processing_card_fee)
    ).toFixed(2);
    total_bank = parseFloat(
      parseFloat(fee_val) + parseFloat(processing_bank_fee)
    ).toFixed(2);
    src_total_card = total_card;
    src_total_bank = total_bank;
    obj = {
      ...obj,
      fee: parseFloat(fee_val).toFixed(2),
      fee_label: fee_label,
      processing_card_fee: processing_card_fee,
      processing_bank_fee: processing_bank_fee,
      total_card: parseFloat(total_card).toFixed(2),
      total_bank: parseFloat(total_bank).toFixed(2),
      src_total_card: parseFloat(src_total_card).toFixed(2),
      src_total_bank: parseFloat(src_total_bank).toFixed(2),
    };
    return obj;
  } else {
    return {
      playerInfo: [
        {
          first_name: "",
          last_name: "",
          email: "",
          telePhone: "",
          birthday: "",
          gender: "",
          grade: "",
          height: "",
          experience: "",
          t_shirt: "",
          sel_trip: "",
          fee: "",
          fee_label: "",
        },
      ],
      feeList: [],
      fee: 0,
      fee_label: "",
      isPayment: false,
      discountCode: "",
      c_first_name: "",
      c_last_name: "",
      c_email: "",
      c_cell_phone: "",
    };
  }
};
