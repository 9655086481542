import React from "react";
import { withRouter } from "react-router";
import { Table } from "antd";
import { showColumnAction } from "./ClubTableColumn";
import "./ClubTable.css";
import { numberWithCommas } from "utils/GlobalFunctions";

function ClubTable(props) {
  const { dataList, onRegister, setSelRows } = props;
  if (dataList === undefined) return <div />;

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      width: 130,
      sorter: (a, b) => `${a.first_name}`.localeCompare(`${b.first_name}`),
    },
    {
      title: "Last Name",
      width: 130,
      dataIndex: "last_name",
      sorter: (a, b) => `${a.last_name}`.localeCompare(`${b.last_name}`),
    },
    {
      title: "DOB",
      dataIndex: "birthday",
      width: 150,
      render: (info) => {
        var formattedTime = "";
        if (info !== undefined && (info != null) & (info != "")) {
          let year = info.split("-")[0];
          let month = info.split("-")[1];
          let day = info.split("-")[2];
          formattedTime = month + "/" + day + "/" + year.substr(-2);
        }

        return formattedTime;
      },
      sorter: (a, b) => `${a.birthday}`.localeCompare(`${b.birthday}`),
    },
    {
      title: "Parent Email",
      dataIndex: "p_email",
      width: 250,
      sorter: (a, b) => `${a.p_email}`.localeCompare(`${b.p_email}`),
    },
    {
      title: "Last Registration Date",
      width: 200,
      dataIndex: "reg_date",
      render: (record) => {
        return record.replaceAll("-", "/");
      },
      sorter: (a, b) => a.reg_date - b.reg_date,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const dataSource =
    dataList.length == 0
      ? []
      : dataList.map((data, index) => {
          return { ...data, key: index };
        });

  return (
    <div className="info-list-widget">
      <Table
        columns={columns}
        dataSource={dataSource}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        scroll={{ x: 900 }}
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
}

export default withRouter(ClubTable);
