import React from 'react';
import MainFooter from './MainFooter';
import MainNav from './MainNav';
import "./layout.css";
// components

const Layout = (props) => {
  const { resStatus, children } = props;

  return (
    <div className='dashboard-main-page'>
      <MainNav resStatus={resStatus} />
      {children}
      <MainFooter />
    </div>
  );
};

export default Layout;
